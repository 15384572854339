import React from "react";
import Faqs from "../faqs/Faqs";
import profile from "../../app/assets/img/aadi-logo-black.png";
import "./MessageScreen.scss";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import logo from "../../app/assets/img/aadi-login-logo.png";
import { useMediaQuery } from 'react-responsive';

const MessageScreen = ({ setUserInput, handleExternalTextClick }) => {
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <div className="messaageScreen">
      <div
        style={{
          fontSize: "34px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          marginLeft: '12px',
        }}
      >
        <img style={{ width:isTab ? "68px":"80px" }} src={logo} alt="Aadi" />
        <span
          style={{
            position: "absolute",
            background: "#37a5c6",
            fontSize: "9px",
            padding: "2px",
            right: "-10px",
            bottom: "-19px",
            fontFamily: "Ahganirya",
            fontWeight: "normal",
            paddingTop: "3px",
            color: "white",
          }}
        >
          BETA
        </span>
      </div>

      <div
        style={{
          fontSize: isTab ? "26px" : "34px",
          fontWeight: "600",
          paddingLeft: "10px",
        }}
      >
        Hello again
      </div>
      <div style={{ paddingLeft: "10px", fontSize:isTab ? "15px":""}}>
        Tell me what’s on your mind, or pick a suggestion.
      </div>

      <div className="human-reviewer-disclosure-banner" style={{display:isTab? "grid" : ""}}>
        <div className="tooltip-icon">
          <TipsAndUpdatesIcon sx={{ fontSize: "30px" }} />
        </div>
        <div className="message-container gmat-body-1">
          <div className="tooltip-content">
            From interpreting and contextualizing queries within enterprise
            frameworks to orchestrating seamless data interactions,
            synthesizing cause-effect relationships, and providing transparent
            explanations through Step Intelligence Maps (SIM), AaDI empowers
            your financial operations with unparalleled insight and clarity.
          </div>
        </div>
      </div>

      <div
        style={{
          paddingLeft: "10px",
          display: "none",
        }}
      >
        Would you like to continue with?
      </div>

      <div
        style={{
          paddingLeft: "10px",
          fontWeight: "600",
          display: "grid",
          gap: "10px",
          textAlign: "left",
        }}
      >
        <Faqs
          handleExternalTextClick={handleExternalTextClick}
          setUserInput={setUserInput}
        />
      </div>
    </div>
  );
};

export default MessageScreen;
