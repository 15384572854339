import React, { useState, useEffect, useRef } from "react";
import "./HistoryResponse.scss";
import HistoryAnswerDisplay from "./HistoryAnswerDisplay";
import Alert from "@mui/material/Alert";
import { Avatar } from "@mui/material";
import DisplayUserInput from "../response/DisplayUserInput";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import PushPinIcon from "@mui/icons-material/PushPin";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CachedIcon from "@mui/icons-material/Cached";

const loadingMessages = [
  { text: "Understanding the questions...", delay: 2000 },
  { text: "Generating SQL...", delay: 2000 },
  { text: "Synthesizing the insights...", delay: 2000 },
  { text: "Preparing reports...", delay: 2000 },
];

const HistoryResponce = ({
  message,
  setIsLoading,
  isLoading,
  index,
  printPDF,
  loadingIndex,
  setResponceData,
  isHistory,
  page,
  newChatStart,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const chatEndRef = useRef(null);
  const [isPinned, setIsPinned] = useState(
    (message && message?.response?.pin_response) || false
  );
  const [pinError, setPinError] = useState(false);
  const [pinSuccess, setPinSuccess] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const history = useHistory();

  const handlePin = async (messageId, child_question_id) => {
    let baseUrl = "";
    let data = null;
    try {
      if (!isPinned) {
        baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/pin`;
        data = {
          messageID: messageId,
          email: `${localStorage.getItem("userinfo")}`,
          child_question_id: child_question_id,
        };
      } else {
        baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/remove/pin`;
        data = {
          messageID: messageId,
          email: `${localStorage.getItem("userinfo")}`,
        };
      }
      setPinLoading(true);
      // const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/pin`;

      // const data = {
      //   messageID: messageId,
      //   email: `${localStorage.getItem("userinfo")}`,
      //   child_question_id: child_question_id,
      // };

      const response = await axios.post(baseUrl, data);

      if (response.status === 200) {
        setPinLoading(false);
        setPinSuccess(true);
        setTimeout(() => {
          setPinSuccess(false);
        }, 2000);
        setIsPinned((prevState) => !prevState);
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setPinLoading(false);
        setPinError(true);
        setTimeout(() => {
          setPinError(false);
        }, 2000);
      }
    } catch (error) {
      setPinLoading(false);
      console.error("Error delete history chat:", error);
      setPinError(true);
      setTimeout(() => {
        setPinError(false);
      }, 2000);
    }
  };

  const appendParamsToHomePage = (params) => {
    let currentUrl = new URL(window.location.origin);
    currentUrl.pathname = "/";

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        currentUrl.searchParams.append(key, params[key]);
      }
    }

    history.replace({
      pathname: currentUrl.pathname,
      search: currentUrl.search,
    });
  };

  useEffect(() => {
    if (isLoading && loadingStep < loadingMessages.length) {
      const timer = setTimeout(() => {
        setLoadingStep((prevState) => prevState + 1);
      }, loadingMessages[loadingStep].delay);

      return () => clearTimeout(timer);
    }
  }, [isLoading, loadingStep]);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <>
      <div id={`message-${index}`} className={`query-container`}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <DisplayUserInput message={message?.response} />

          <div
            onClick={() => {
              let params = {
                historyQuery: message?.response.query,
              };

              appendParamsToHomePage(params);
            }}
            title="Re Ask"
            style={{ cursor: "pointer" }}
          >
            <CachedIcon />
          </div>
        </div>

        <div className="answer-card">
          <div
            className={`answer-cont-history`}
            style={
              {
                // overflowX: isTab ? "auto" : "visible",
                // whiteSpace: isTab ? "nowrap" : "normal",
              }
            }
          >
            <div className="avatar-container">
              <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
            </div>
            {message?.response?.summary_of_summaries &&
              message?.response?.summary_of_summaries.toLowerCase() !== "na" &&
              message?.response?.summary_of_summaries.toLowerCase() !==
                "n/a" && (
                <>
                  <div className="summary-section">
                    {/* <h3>Overall Summary</h3> */}
                    <p>{message?.response?.summary_of_summaries}</p>
                  </div>
                  <h3 className="supporting-txt">Supporting Facts</h3>
                </>
              )}
            <div className="main-card">
              {message?.response?.content.map((item, index) => (
                <HistoryAnswerDisplay
                  key={item.child_question_id}
                  item={item}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  responseId={message?.response.message_id}
                  setOpen={setOpen}
                  open={open}
                  index={item.child_question_id}
                  responseNumber={message?.response?.content.length}
                  isPinned={isPinned}
                  pinError={pinError}
                  handlePin={handlePin}
                  message={message}
                  pinLoading={pinLoading}
                />
              ))}
            </div>
            <div className="pin-message">
              {pinError && (
                <Alert severity="error">
                  Something went wrong. please try again.
                </Alert>
              )}
              {pinSuccess && (
                <Alert severity="success">
                  {isPinned
                    ? "Response pinned successfully."
                    : "Response unpinned successfully."}
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryResponce;
