import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Getusers from "../auth/Getusers";
import { Avatar, Badge } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../app/assets/img/aadi-login-logo.png";
import Settings from "./Settings";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isTab",
})(({ theme, open, isTab }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(isTab
    ? {}
    : open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      }),
  ...(!isTab &&
    !open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${84}px)`,
    }),
}));

const Topnavbar = ({
  dataItems,
  open,
  setCompany,
  setOpen,
  setHistoySections,
  HistoySections,
  setopenDialog,
  setisDefaultPage,
  page,
  toggleDrawer,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [viewSettings, setViewSettings] = useState(false);
  const history = useHistory();

  const isHomePage = location.pathname === "/";
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  const drawerWidth = 250;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white", boxShadow: "none", zIndex: "1" }}
        open={open}
        isTab={isTab}
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            paddingTop: "20px",
            alignItems: "center",
            paddingBottom: "20px",
            height: "72px",
            background: "black",
            boxShadow: "0 4px 8px rgba(255, 255, 255, 0.2)",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            style={{
              color: "#253978",
              fontSize: "24px",
              fontWeight: "700",
              textAlign: "left",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              alignContent: "center",
            }}
            noWrap
            component="div"
          ></Typography>

          <div
            style={{
              width: "100%",
              gridGap: "3rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
            id="top-nav"
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (page && page === "Home") {
                  setisDefaultPage(true);
                } else {
                  history.push("/");
                }
              }}
            >
              <div
                style={{
                  fontSize: "34px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                  gap: "5px",
                }}
              >
                <img style={{ width: "60px" }} src={Logo} alt="logo" />

                <span
                  style={{
                    position: "absolute",
                    background: "#37a5c6",
                    fontSize: "9px",
                    padding: "2px",
                    right: "-15px",
                    bottom: "-10px",
                    fontFamily: "Ahganirya",
                    fontWeight: "normal",
                    paddingTop: "3px",
                    color: "white",
                  }}
                >
                  BETA
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "2rem",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  alignContent: "center",
                  border: isTab ? "none" : "1px solid #eee",
                  padding: "5px",
                  borderRadius: "100px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                }}
              >
                {!isTab && (
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "flex-end",
                      textAlign: "right",
                      justifyItems: "flex-end",
                    }}
                  >
                    <Getusers className="NameHeader" name={true} />
                    <Getusers className="NameHeadeEmail" email={true} />
                  </div>
                )}

                <div
                  className="avatar-cont"
                  onClick={() => setViewSettings((prevState) => !prevState)}
                >
                  <Avatar
                    sx={{
                      background: "#bb1ccc",
                      width: "35px",
                      height: "35px",
                    }}
                  >
                    <Getusers className="fontsizeHeader" trim={true} />
                  </Avatar>
                </div>
              </div>
              {viewSettings && (
                <Settings
                  viewSettings={viewSettings}
                  setViewSettings={setViewSettings}
                  setopenDialog={setopenDialog}
                />
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Topnavbar;
