import React from "react";
import "./ScoreTable.scss";

// ObjectToTable component definition
const ScoreTable = ({ data, title }) => {
  // Extract keys and values from the object
  const keys = Object.keys(data);
  const values = Object.values(data);

  function replaceUnderscores(str) {
    // Use the replace method with a regular expression to find all underscores and replace them with spaces
    return str.replace(/_/g, " ");
  }

  const customOrder = ["Intent Translation","Question Similarity","Entity Mapping","Code Generation","Summary Quality","Visualization Compliance Score",'Visualization Asthetics',"Insight Alignment","Total",];

  const rearrange = (keys, values, order) => {
    const rearranged = keys
      .map((key, index) => ({ key, value: values[index] }))
      .sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
    return rearranged;
  };

  const rearrangedData = rearrange(keys, values, customOrder);

  return (
    <div className="score-table-cont">
      <h4>{title}</h4>
      <table id="chatTable">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {/* Render rows for each key-value pair */}
          {rearrangedData.map((item, index) => (
          <tr className={`${item.key === 'Total' ? 'TotalCls': ''}`} key={index}>
            <td style={{ textTransform: "capitalize" }}>
              {replaceUnderscores(item.key)}
            </td>
            <td>{item.value.toFixed(2)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
