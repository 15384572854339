import React, { useState, useEffect, useRef } from "react";
import "./FavChatResponse.scss";
import FavAnswerDisplay from "./FavAnswerDisplay";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useHistory } from "react-router-dom";
const loadingMessages = [
  { text: "Understanding the questions...", delay: 2000 },
  { text: "Generating SQL...", delay: 2000 },
  { text: "Synthesizing the insights...", delay: 2000 },
  { text: "Preparing reports...", delay: 2000 },
];

const FavResponse = ({
  message,
  setIsLoading,
  isLoading,
  index,
  page,
  fetchpinData,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const chatEndRef = useRef(null);
  const [isPinned, setIsPinned] = useState(
    (message && message?.pin_response) || false
  );
  const [pinError, setPinError] = useState(false);
  const [pinSuccess, setPinSuccess] = useState(false);
  const history = useHistory();
  const [pinLoading, setPinLoading] = useState(false);

  const handlePin = async (messageId) => {
    try {
      setPinLoading(true)
      const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/remove/pin`;

      const data = {
        messageID: messageId,
        email: `${localStorage.getItem("userinfo")}`,
      };

      const response = await axios.post(baseUrl, data);

      if (response.status === 200) {
        setPinLoading(false)
        setIsPinned(true);
        setPinSuccess(true);
        fetchpinData();
        setTimeout(() => {
          setPinSuccess(false);
          if (page === "Pin") {
            setIsPinned(false);
          }
        }, 2000);
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setPinLoading(false)
        setPinError(true);
        setTimeout(() => {
          setPinError(false);
        }, 2000);
      }
    } catch (error) {
      setPinLoading(false)
      console.error("Error delete history chat:", error);
      setPinError(true);
      setTimeout(() => {
        setPinError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isLoading && loadingStep < loadingMessages.length) {
      const timer = setTimeout(() => {
        setLoadingStep((prevState) => prevState + 1);
      }, loadingMessages[loadingStep].delay);

      return () => clearTimeout(timer);
    }
  }, [isLoading, loadingStep]);

  
  return (
    <>
      <div id={`message-${index}`} className={`query-container`}>
        <div className="answer-card">
          <div className={`answer-cont-fav`}>
            <div className="main-card">
              {message?.content.map((item, index) => (
                <FavAnswerDisplay
                  key={item.child_question_id}
                  item={item}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  responseId={message?.message_id}
                  setOpen={setOpen}
                  open={open}
                  index={item.child_question_id}
                  responseNumber={message?.content.length}
                  isPinned={isPinned}
                  pinError={pinError}
                  handlePin={handlePin}
                  message={message}
                  pinLoading={pinLoading}
                />
              ))}
            </div>
            <div className="pin-message">
              {pinError && (
                <Alert severity="error">
                  Something went wrong. please try again.
                </Alert>
              )}
              {pinSuccess && (
                <Alert severity="success">
                  Response unpinned successfully.
                </Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavResponse;
