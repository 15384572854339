import React, { useState, useEffect, useRef } from "react";
import "./ChatDisplay.scss";
import ChatResponse from "./ChatResponse";

const ChatDisplay = ({
  setIsLoading,
  isLoading,
  responceData,
  query,
  printPDF,
  loadingIndex,
  setResponceData,
  isHistory,
  page
}) => {

  // console.log('Response: ', responceData);

  return (
    <div className="response-cont">
      {responceData.map((message, index) => (
        <ChatResponse
          key={index}
          message={message}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          index={index}
          printPDF={printPDF}
          loadingIndex={loadingIndex}
          setResponceData={setResponceData}
          isHistory={isHistory}
          page={page}
        />
      ))}
    </div>
  );
};

export default ChatDisplay;
