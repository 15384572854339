import React, { useState } from "react";
import "./MessagePopup.scss";
import Button from "@mui/material/Button";
import PlotlyGrapgh from "../layout/PlotlyGrapgh";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DisplayResponse from "./DisplayResponse";
import { useMediaQuery } from "react-responsive";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./PlotlyPopup.scss";
const PlotlyPopup = ({
  open,
  setOpen,
  graph,
  messageID,
  childId,
  version,
  tableCoumn,
  setPlotlyGraph,
  setmessageGrapgh,
  messageGrapgh

}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      id="plotly-popup"
    >
      {/* <DialogTitle>{"Why did you choose this rating? (optional)"}</DialogTitle> */}

      {isMobile && <IconButton
        onClick={() => setOpen(false)}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>}

      <DialogContent>
        {open && (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
            }}
            className="plotly-cont"
          >
            <PlotlyGrapgh
              graph={graph}
              messageID={messageID}
              childId={childId}
              version={version}
              tableCoumn={tableCoumn}
              setPlotlyGraph={setPlotlyGraph}
              setmessageGrapgh={setmessageGrapgh}
              messageGrapgh={messageGrapgh}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PlotlyPopup;
