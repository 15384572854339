import React, { Fragment, forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import "./feedback.scss";
import { Label } from "semantic-ui-react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from "axios";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Feedback({ messageID, printPDF, feedbackStatus,index,child_response_id }) {
  const [open, setOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [activeFeedback, setActiveFeedback] = useState([]);
  const [feedbackSubmit, setFeedbackSubmit] = useState(false);
  const [feedbackTextArea, setFeedbackTextArea] = useState("");
  const [feedbackSubmitDone, setFeedbackSubmitDone] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(feedbackStatus);

  const handleClose = () => {
    setOpen(false);
    setActiveFeedback([]);
    setFeedbackTextArea("");
    if(!feedbackSubmitDone){
      setFeedbackText("")
    }
    else{
      setFeedbackText(feedbackStatus)
    }

    
  };

  const giveFeedback = (id, feedback) => {
    setOpen(true);
    setFeedbackText(feedback);
  };

  const GoodFeedback = ["Correct", "Easy to Understand", "Complete"];
  const BadFeedback = ["Incorrect SQL", "Incorrect Summary", "Visualization", "All", "Others"];

  const handleFeedbackSelection = (type, item) => {
    if (type === "Good" || type === "Bad") {
      if (activeFeedback.includes(item)) {
        setActiveFeedback(activeFeedback.filter((i) => i !== item));
      } else {
        setActiveFeedback([...activeFeedback, item]);
      }
    }
  };

  const handleSubmitFeedback = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/feedback`;
    setFeedbackSubmit(true);

    const feedbackData = {
      Comment: feedbackTextArea,
      additionalComment: activeFeedback,
      messageID: messageID + '',
      feedback: feedbackText,
      email: localStorage.getItem("userinfo"),
      child_response_id:child_response_id
    };

    try {
      const response = await axios.post(baseUrl, feedbackData);
      if (response.status === 200) {
        setFeedbackSubmitDone(true)
        setFeedbackSubmit(false);
        handleClose();
        setFeedbackShow(feedbackText)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          alignContent: "center",
          marginBottom: "1rem",
        }}
      >
        <span
          title="Good Response"
          style={{ cursor: "pointer" }}
          onClick={() => giveFeedback("1", "Good")}
        >
          <ThumbUpAltOutlinedIcon sx={{ color: feedbackShow === "Good" ? '#bb1ccc' : 'inherit' }} />
        </span>

        <span
          title="Bad Response"
          style={{ cursor: "pointer", paddingTop: "2px" }}
          onClick={() => giveFeedback("2", "Bad")}
        >
          <ThumbDownOutlinedIcon sx={{ color: feedbackShow === "Bad" ? '#bb1ccc' : 'inherit' }} />
        </span>

        {/* <span
          title="Print PDF"
          style={{ cursor: "pointer", paddingTop: "2px" }}
          onClick={() => printPDF(index)}
        >
          <PictureAsPdfIcon />
        </span> */}
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        id="feedback"
      >
        <DialogTitle>{"Why did you choose this rating? (optional)"}</DialogTitle>
        <DialogContent>
          <div className="feedback">
            {feedbackText === "Good" ? (
              <>
                {/* {GoodFeedback.map((item) => (
                  <div
                    key={item}
                    className={`items ${activeFeedback.includes(item) ? "activeBtn" : ""}`}
                    onClick={() => handleFeedbackSelection("Good", item)}
                  >
                    {item}
                  </div>
                ))} */}
              </>
            ) : (
              <>
                {BadFeedback.map((item) => (
                  <div
                    key={item}
                    className={`items ${activeFeedback.includes(item) ? "activeBtn" : ""}`}
                    onClick={() => handleFeedbackSelection("Bad", item)}
                  >
                    {item}
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="comments-main">
            <Label>Comments</Label>
            <textarea
              className="commentsArea"
              placeholder="Your Feedback (optional)"
              rows={5}
              onChange={(e) => setFeedbackTextArea(e.target.value)}
            />
          </div>

          <div style={{ fontSize: "12px" }}>
            Feedback submitted will also include up to the last 24 hours of your conversations to help improve Aadi.
          </div>
        </DialogContent>
        <DialogActions style={{ marginRight: "1rem", marginBottom: "5px" }}>
          <Button
            style={{ width: "auto",  }}
            className="cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "white", width: "auto" }}
            className="submit_btn"
            onClick={handleSubmitFeedback}
            disabled={feedbackSubmit}
          >
            {feedbackSubmit ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span style={{ paddingLeft: "10px" }}>Submitting...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
