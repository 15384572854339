import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { TextField } from "@mui/material";
import "./DisplayPlotlyGraph.scss";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function DisplayPlotlyGrapgh({
  messageID,
  childId,
  setGraphData,
  version,
  headers
}) {
  const [open, setOpen] = useState(false);
  const [ChangePrompt, setChangePrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [updatedVersion, setUpdatedVersion] = useState(version);
  const [activeHeader, setActiveHeader] = useState([]);
  const [headerCount, setHeaderCount] = useState(0);
  const inputRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePlot = async (type) => {
    setLoading(true);
    try {
      let baseurl = "";
      let data = {};
      if (type === "reset" || type === "undo") {
        baseurl = `${process.env.REACT_APP_PYTHON_BACKEND_URL}/reset`;
        if (type === "reset") {
          let body = {
            type: "reset",
            message_id: messageID,
            child_id: parseInt(childId),
            version: parseInt(updatedVersion),
          };
          data = { ...body };
        } else if (type === "undo") {
          let body = {
            type: "undo",
            message_id: messageID,
            child_id: parseInt(childId),
            version: updatedVersion,
          };
          data = { ...body };
        }
      } else {
        baseurl = `${process.env.REACT_APP_PYTHON_BACKEND_URL}/update_plot`;
        data = {
          message_id: messageID,
          child_question_id: parseInt(childId),
          Change_prompt: ChangePrompt,
          Version: parseInt(updatedVersion),
        };
      }

      const response = await axios.post(baseurl, data);

      if (response.status === 200) {
        setOpen(false);
        const data = response.data;
        const fig = data.figure;
        
        setGraphData(fig);
        setLoading(false);
        if (type === "undo") {
          setUpdatedVersion((prevState) => +prevState - 1);
        } else if (type === "reset") {
          setUpdatedVersion((prevState) => 1);
        } else {
          setUpdatedVersion((prevState) => +prevState + 1);
        }
        return response.data;
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setOpen(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error update plot history chat:", error);
      setOpen(false);
      setLoading(false);
    }
  };

  const handleChangePrompt = useCallback(
    debounce((newPrompt) => {
      if (newPrompt === "") {
        setActiveHeader([]);
        setHeaderCount(0);
      }
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    handleChangePrompt(ChangePrompt);
  }, [ChangePrompt, handleChangePrompt]);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setActiveHeader((prevState) => {
        return prevState.filter((str) => ChangePrompt.includes(str));
      });
    }
  };

  return (
    <Fragment>
      <Button
        style={{ marginTop: "12px" }}
        variant="outlined"
        onClick={() => {
          handleClickOpen();
          setChangePrompt("");
          setActiveHeader([]);
          setHeaderCount(0);
        }}
        className="submit_btn"
      >
        Replot
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="replot-dialog"
      >
        <DialogTitle>Replot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write a prompt to explain the system on how you want update
            the visualization.
          </DialogContentText>
          <TextField
            ref={inputRef}
            autoFocus
            required
            margin="dense"
            id="query"
            name="query"
            label="Insert your prompt here"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setChangePrompt(e.target.value)}
            value={ChangePrompt}
            onKeyDown={handleKeyDown}
          />

          {headers && (
            <div style={{ display: "grid", gap: "10px", paddingTop: "5px" }}>
              <div style={{ fontWeight: "600" }}>Existing Columns</div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  paddingBottom: "10px",
                }}
              >
                {headers &&
                  headers.map((item) => {
                    return (
                      <div
                        style={{
                          border: "1px solid #bb1ccc",
                          padding: "5px",
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                        className={`header-list ${
                          activeHeader.includes(item) ? "active-header" : ""
                        }`}
                        onClick={() => {
                          if (headerCount > 0 && activeHeader.includes(item))
                            return;
                          setChangePrompt(
                            (prevState) => prevState + " " + item
                          );
                          inputRef.current.querySelector("input").focus();
                          setActiveHeader((prevState) => [...prevState, item]);
                          setHeaderCount(1);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div
            className={`button-container ${
              +updatedVersion === 1 ? "move-right" : ""
            }`}
          >
            {+updatedVersion !== 1 && (
              <div className="changes-btns">
                <Button
                  className={`cancel-btn ${
                    loading && action === "undo" ? "updating" : ""
                  }`}
                  onClick={() => {
                    setAction("undo");
                    handleChangePlot("undo");
                  }}
                  autoFocus
                >
                  {loading && action === "undo" ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span style={{ paddingLeft: "10px" }}>Updating...</span>
                    </>
                  ) : (
                    "Undo"
                  )}
                </Button>
                <Button
                  className={`cancel-btn ${
                    loading && action === "reset" ? "updating" : ""
                  }`}
                  onClick={() => {
                    setAction("reset");
                    handleChangePlot("reset");
                  }}
                  autoFocus
                >
                  {loading && action === "reset" ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span style={{ paddingLeft: "10px" }}>Updating...</span>
                    </>
                  ) : (
                    "Reset"
                  )}
                </Button>
              </div>
            )}
            <div className="action-btns">
              <Button className="cancel-btn" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={`submit_btn ${loading && !action ? "updating" : ""}`}
                onClick={() => {
                  setAction("");
                  handleChangePlot();
                }}
                autoFocus
              >
                {loading && !action ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span style={{ paddingLeft: "10px" }}>Updating...</span>
                  </>
                ) : (
                  "Update"
                )}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
