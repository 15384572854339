import React, { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FaTrash } from "react-icons/fa";
import "./DeleteHistory.scss";
import axios from "axios";

const DeleteHistory = ({
  questions,
  responseID,
  setSuccessHistoryloading,
  HistoryData,
  newChatStart,
}) => {
  const [open, setOpen] = useState(false);
  const [deleteChat, setdeleteChat] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeHistoryItem = async () => {
    setdeleteChat(true);
    try {
      const baseurl = `${process.env.REACT_APP_BASE_URL}/aadi/history/delete`;

      const data = { responseID: responseID };

      const response = await axios.post(baseurl, data);

      if (response.status === 200) {
        HistoryData();
        setSuccessHistoryloading(true);
        setdeleteChat(false);
        setOpen(false);
        newChatStart();

        setTimeout(() => {
          setSuccessHistoryloading(false);
        }, 2000);

        return response.data;
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setdeleteChat(false);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error delete history chat:", error);
      setdeleteChat(false);
      setOpen(false);
    }
  };

  return (
    <div id="DeleteHistory">
      <Fragment>
        <div onClick={handleClickOpen}>
          <FaTrash
            fontSize={12}
            style={{
              color: "#ffffff",
              paddingTop: "2px",
            }}
          />
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="dialogDeleteHistory"
        >
          <DialogTitle id="alert-dialog-title">{"Delete chat?"}</DialogTitle>
          <DialogContent style={{ minWidth: "400px !important" }}>
            <DialogContentText id="alert-dialog-description">
              This will delete {questions}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ width: "auto", color: "white", height: "35px" }}
              className="submit_btn"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              disabled={deleteChat}
              style={{ width: "auto", color: "white", height: "35px" }}
              className="submit_btn"
              onClick={removeHistoryItem}
            >
              {deleteChat ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span style={{ paddingLeft: "5px" }}>Deleting..</span>
                </>
              ) : (
                "Yes"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};
export default DeleteHistory;
