import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import bgimage from "../assets/img/Aays-login-page-img-2.jpg";
import logo from "../assets/img/Aays.png";
import Home from "../../pages/Home/Home";
import aadiLogo from '../assets/img/aadi-login-logo.png'
import "bootstrap/dist/css/bootstrap.min.css";
import "./SigninWidget.scss";

export default withOktaAuth(
  class SigninWidget extends Component {
    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    async login() {
      await this.props.oktaAuth.signInWithRedirect();
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      let body = null;
      if (this.props.authState?.isAuthenticated) {
        body = (
          <div className="Buttons">
            <Home />
          </div>
        );
      } else {
        body = (
          <div className="Buttons">
            <div className="loginPage">
              <div className="login_body_content">
                <div className="bg-white dark:bg-gray-900 bgColor">
                  <div className="login_body_grid">
                    <div
                      // style={{ backgroundImage: `url(${bgimage})` }}
                      className="hidden bg-cover lg:block lg:w-2/3 login_bg_image"
                    >
                      <div className="login_info">
                        <div className="text-container">
                          <img style={{width:"150px"}} src={logo} alt="Aays" className="logo-img" />
                          <div
                            style={{
                              display: "grid",
                              justifyContent: "center",
                            }}
                            className="text-4xl font-bold text-white"
                          >
                            <h1
                              style={{
                                fontSize: "28px",
                                width: "60%",
                                textAlign: "left",
                                lineHeight: "1.25",
                                marginBottom: "8px",
                                fontWeight:'700'
                              }}
                            >
                              Aays Decision Intelligence Co-Pilot for Finance
                            </h1>
                          </div>

                          <p className="left_grid_text">
                            Welcome to the Flux reporting and variance analytics
                            tool. Access to this system is limited - please
                            contact{" "}
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              href="mailto:anshuman.bhar@aaysanalytics.com"
                            >
                              anshuman.bhar@aaysanalytics.com
                            </a>{" "}
                            for details.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="right_grid_align">
                      <div className="flex-1 right-container">
                        <div className="text-center">
                          <span className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo">
                            <img width={100} src={aadiLogo} alt="Aadi" />
                          </span>

                          <p className="signin-text">
                            Sign in to access your account
                          </p>
                        </div>

                        <div className="mt-8">
                          <div className="mt-6">
                            <button className="btn_login" onClick={this.login}>
                              Sign in
                            </button>
                          </div>
                        </div>

                        <div
                          style={{
                            width: "70%",
                            paddingTop: "1.25rem",
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          <span
                            id="Login_herf"
                            style={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              className="loginText1 formLableText1"
                              style={{ textDecoration: "none" }}
                              href="mailto:anshuman.bhar@aaysanalytics.com?subject=Need Access to the Lab Analytics BI Portal - Pilot Applications!"
                            >
                              {" "}
                              Need Access?
                            </a>

                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              className="loginText1 formLableText1"
                              style={{ textDecoration: "none" }}
                              href="mailto:anshuman.bhar@aaysanalytics.com?subject=Need Help On Sign in On Lab Analytics BI Portal - Pilot Applications!"
                            >
                              {" "}
                              Need Help?
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return <div className="App">{body}</div>;
    }
  }
);
