import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, memo, useCallback } from "react";
import { FaTrash } from "react-icons/fa";
import HistoryIcon from "@mui/icons-material/History";
import DeleteHistory from "../layout/DeleteHistory";
import "./History.scss";
import HistoryLoadingPage from "../layout/HistoryLoadingPage";
import { useHistory } from "react-router-dom";

const History = React.memo(
  ({
    handleExampleClick,
    nodataImage,
    fetchHistoryDataItem,
    isLoading,
    setSuccessHistoryloading,
    newChatStart,
    historyFlag,
    setHistoryFlag,
    historyDataFlag,
    setHistoryDataFlag,
  }) => {
    const [History, setHistory] = useState(() => {
      const storedValue = JSON.parse(localStorage.getItem("historyData"));

      return storedValue ? storedValue : [];
    });
    const [Historyloading, setHistoryloading] = useState(false);
    const [todayItems, setTodayItems] = useState([]);
    const [yesterdayItems, setYesterdayItems] = useState([]);
    const [lastWeekItems, setLastWeekItems] = useState([]);
    const [thisMonthItems, setThisMonthItems] = useState([]);
    const [activeItem, setActiveItem] = useState(() => {
      const storedValue = localStorage.getItem("activeItem");
      return storedValue ? storedValue : "";
    });
    const history = useHistory();

    const cleanSummary = (summary) => {
      if (summary?.startsWith('"') && summary?.endsWith('"')) {
        summary = summary.substring(1, summary.length - 1);
      }
      return summary;
    };

    const filterFaqData = (data) => {
      const today = new Date();
      const todayUTC = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate()
        )
      );
      const yesterdayUTC = new Date(todayUTC);
      yesterdayUTC.setUTCDate(todayUTC.getUTCDate() - 1);
      const startOfWeekUTC = new Date(todayUTC);
      startOfWeekUTC.setUTCDate(todayUTC.getUTCDate() - todayUTC.getUTCDay());
      const startOfMonthUTC = new Date(
        Date.UTC(todayUTC.getUTCFullYear(), todayUTC.getUTCMonth(), 1)
      );

      const isSameDay = (date1, date2) =>
        date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0];

      const filteredToday = data.filter((item) =>
        isSameDay(new Date(item.CreatedDate), todayUTC)
      );
      const filteredYesterday = data.filter((item) =>
        isSameDay(new Date(item.CreatedDate), yesterdayUTC)
      );
      const filteredLastWeek = data.filter((item) => {
        const itemDate = new Date(item.CreatedDate);
        return (
          itemDate >= startOfWeekUTC &&
          itemDate < todayUTC &&
          !isSameDay(itemDate, todayUTC) &&
          !isSameDay(itemDate, yesterdayUTC)
        );
      });
      const filteredThisMonth = data.filter((item) => {
        const itemDate = new Date(item.CreatedDate);
        return (
          itemDate >= startOfMonthUTC &&
          !isSameDay(itemDate, todayUTC) &&
          !isSameDay(itemDate, yesterdayUTC) &&
          !filteredLastWeek.includes(item)
        );
      });

      setTodayItems(filteredToday);
      setYesterdayItems(filteredYesterday);
      setLastWeekItems(filteredLastWeek);
      setThisMonthItems(filteredThisMonth);
    };

    const HistoryData = useCallback(async () => {
      const emailId = localStorage.getItem("userinfo");
      try {
        const baseurl = `${process.env.REACT_APP_BASE_URL}/aadi/history/List?email=${emailId}`;
        setHistoryloading(true);
        const response = await axios.get(baseurl);
        if (response.status === 200) {
          setHistory(response.data);
          setHistoryloading(false);
          filterFaqData(response.data);
          localStorage.removeItem("historyData", JSON.stringify(response.data));
          localStorage.setItem("historyData", JSON.stringify(response.data));
          // localStorage.setItem("historyFlag", 1);
          setHistoryFlag((prevState) => 1);
        } else {
          console.error(
            "Error fetching History data:",
            response.status,
            response.statusText
          );
          setHistoryloading(false);
        }
      } catch (error) {
        console.error("Error fetching History data:", error);
        setHistoryloading(false);
      }
    }, []);

    useEffect(() => {
      let historyListFlag = localStorage.getItem("historyListFlag");
      if (historyListFlag === "true") {
        HistoryData();
        localStorage.setItem("historyListFlag", false);
      }
      const storedValue = JSON.parse(localStorage.getItem("historyData"));

      if (storedValue !== null) {
        filterFaqData(storedValue);
        setHistory(storedValue);
      }
    }, []);

    let currentUrl = new URL(window.location.href);
    const appendParamsToHomePage = (params) => {
      let currentUrl = new URL(window.location.origin);
      currentUrl.pathname = "/";

      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          currentUrl.searchParams.append(key, params[key]);
        }
      }

      history.replace({
        pathname: currentUrl.pathname,
        search: currentUrl.search,
      });
    };

    useEffect(() => {
      if (historyDataFlag) {
        HistoryData();
        setHistoryDataFlag(false);
      }
    }, [historyDataFlag]);

    return (
      <div className="faqSections">
        <>
          <div className="card text-center">
            <div className="card-body">
              <div style={{ display: "grid", gap: "10px" }}>
                {Historyloading && (
                  <div style={{ padding: "0.5rem", display: "grid" }}>
                    {[...Array(6)].map((_, index) => (
                      <Skeleton
                        style={{ backgroundColor: "#eee" }}
                        key={index}
                        height={60}
                      />
                    ))}
                  </div>
                )}
                {!Historyloading && (
                  <>
                    {History.length !== 0 && (
                      <>
                        <div style={{ display: "grid", gap: "16px" }}>
                          {/* <p className="history-heading">Recent History</p> */}
                          {todayItems.length !== 0 && (
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                  position: "sticky",
                                  top: "0",
                                  background: "black",
                                  padding: "0px 11px 12px 6px",
                                  zIndex: "1000",
                                }}
                              >
                                Today
                              </div>
                              {todayItems.map((item) => (
                                <div
                                  key={item.Id}
                                  id={`faqsId_${item.Id}`}
                                  className={`${"bg-dark-card"} 
                                   ${activeItem === item.responseID
                                      ? "activeHistoryItem"
                                      : ""
                                    } `}
                                >
                                  <div
                                    style={{
                                      gridGap: "7px",
                                      alignItems: "baseline",
                                    }}
                                    className="text-start faqtext"
                                  >
                                    <span
                                      onClick={() => {
                                        if (currentUrl.pathname === "/") {
                                          fetchHistoryDataItem(
                                            item.responseID,
                                            item.summary,
                                            item.Id
                                          );
                                        } else {
                                          let params = {
                                            responseId: item.responseID,
                                            summary: item.summary,
                                            chatId: item.Id,
                                          };

                                          appendParamsToHomePage(params);
                                        }
                                        localStorage.setItem(
                                          "activeItem",
                                          item.responseID
                                        );
                                      }}
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>
                                        <HistoryIcon
                                          sx={{
                                            color: "#ffffff",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </span>
                                      <span className="history-text">
                                        {cleanSummary(item.summary)}
                                      </span>
                                    </span>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        display: "grid",
                                      }}
                                    >
                                      <DeleteHistory
                                        setSuccessHistoryloading={
                                          setSuccessHistoryloading
                                        }
                                        questions={cleanSummary(item.summary)}
                                        responseID={item.responseID}
                                        HistoryData={HistoryData}
                                        newChatStart={newChatStart}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {yesterdayItems.length !== 0 && (
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                  position: "sticky",
                                  top: "0",
                                  background: "black",
                                  padding: "0px 11px 12px 6px",
                                  zIndex: "2000",
                                }}
                              >
                                Yesterday
                              </div>
                              {yesterdayItems.map((item) => (
                                <div
                                  key={item.Id}
                                  id={`faqsId_${item.Id}`}
                                  className={`${"bg-dark-card"} 
                                    ${activeItem === item.responseID
                                      ? "activeHistoryItem"
                                      : ""
                                    } `}
                                >
                                  <div
                                    style={{
                                      gridGap: "7px",
                                      alignItems: "baseline",
                                    }}
                                    className="text-start faqtext"
                                  >
                                    <span
                                      onClick={() => {
                                        if (currentUrl.pathname === "/") {
                                          fetchHistoryDataItem(
                                            item.responseID,
                                            item.summary,
                                            item.Id
                                          );
                                        } else {
                                          let params = {
                                            responseId: item.responseID,
                                            summary: item.summary,
                                            chatId: item.Id,
                                          };

                                          appendParamsToHomePage(params);
                                        }
                                        localStorage.setItem(
                                          "activeItem",
                                          item.responseID
                                        );
                                      }}
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>
                                        <HistoryIcon
                                          sx={{
                                            color: "#ffffff",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </span>
                                      <span className="history-text">
                                        {cleanSummary(item.summary)}
                                      </span>
                                    </span>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        display: "grid",
                                      }}
                                    >
                                      <DeleteHistory
                                        setSuccessHistoryloading={
                                          setSuccessHistoryloading
                                        }
                                        questions={cleanSummary(item.summary)}
                                        responseID={item.responseID}
                                        HistoryData={HistoryData}
                                        newChatStart={newChatStart}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {lastWeekItems.length !== 0 && (
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                  position: "sticky",
                                  top: "0",
                                  background: "black",
                                  padding: "0px 11px 12px 6px",
                                  zIndex: "3000",
                                }}
                              >
                                Last Week
                              </div>
                              {lastWeekItems.map((item) => (
                                <div
                                  key={item.Id}
                                  id={`faqsId_${item.Id}`}
                                  className={`${"bg-dark-card"} 
                                    ${activeItem === item.responseID
                                      ? "activeHistoryItem"
                                      : ""
                                    } `}
                                >
                                  <div
                                    style={{
                                      gridGap: "7px",
                                      alignItems: "baseline",
                                    }}
                                    className="text-start faqtext"
                                  >
                                    <span
                                      onClick={() => {
                                        if (currentUrl.pathname === "/") {
                                          fetchHistoryDataItem(
                                            item.responseID,
                                            item.summary,
                                            item.Id
                                          );
                                        } else {
                                          let params = {
                                            responseId: item.responseID,
                                            summary: item.summary,
                                            chatId: item.Id,
                                          };

                                          appendParamsToHomePage(params);
                                        }
                                        localStorage.setItem(
                                          "activeItem",
                                          item.responseID
                                        );
                                      }}
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>
                                        <HistoryIcon
                                          sx={{
                                            color: "#ffffff",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </span>
                                      <span className="history-text">
                                        {cleanSummary(item.summary)}
                                      </span>
                                    </span>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        display: "grid",
                                      }}
                                    >
                                      <DeleteHistory
                                        setSuccessHistoryloading={
                                          setSuccessHistoryloading
                                        }
                                        questions={cleanSummary(item.summary)}
                                        responseID={item.responseID}
                                        HistoryData={HistoryData}
                                        newChatStart={newChatStart}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {thisMonthItems.length !== 0 && (
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",
                                  position: "sticky",
                                  top: "0",
                                  background: "black",
                                  padding: "0px 11px 12px 6px",
                                  zIndex: "4000",
                                }}
                              >
                                This Month
                              </div>
                              {thisMonthItems.map((item) => (
                                <div
                                  key={item.Id}
                                  id={`faqsId_${item.Id}`}
                                  className={`${"bg-dark-card"} 
                                    ${activeItem === item.responseID
                                      ? "activeHistoryItem"
                                      : ""
                                    } `}
                                >
                                  <div
                                    style={{
                                      gridGap: "7px",
                                      alignItems: "baseline",
                                    }}
                                    className="text-start faqtext"
                                  >
                                    <span
                                      onClick={() => {
                                        if (currentUrl.pathname === "/") {
                                          fetchHistoryDataItem(
                                            item.responseID,
                                            item.summary,
                                            item.Id
                                          );
                                        } else {
                                          let params = {
                                            responseId: item.responseID,
                                            summary: item.summary,
                                            chatId: item.Id,
                                          };

                                          appendParamsToHomePage(params);
                                        }
                                        localStorage.setItem(
                                          "activeItem",
                                          item.responseID
                                        );
                                      }}
                                      style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span>
                                        <HistoryIcon
                                          sx={{
                                            color: "#ffffff",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </span>
                                      <span className="history-text">
                                        {cleanSummary(item.summary)}
                                      </span>
                                    </span>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        display: "grid",
                                      }}
                                    >
                                      <DeleteHistory
                                        setSuccessHistoryloading={
                                          setSuccessHistoryloading
                                        }
                                        questions={cleanSummary(item.summary)}
                                        responseID={item.responseID}
                                        HistoryData={HistoryData}
                                        newChatStart={newChatStart}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
);

export default History;
