import React from "react";
import SideNavbar from "../../app/nav/SideNavbar";
import Box from "@mui/material/Box";

const MainLayout = React.memo(
  ({
    colorCodeDataItem,
    saved,
    setisDefaultPage,
    setHistoySections,
    HistoySections,
    nodataImage,
    isLoading,
    fetchHistoryDataItem,
    setSuccessHistoryloading,
    newChatStart,
    historyFlag,
    setHistoryFlag,
    isDefaultPage,
    page,
    historyDataFlag,
    setHistoryDataFlag
  }) => {
    return (
      <Box>
        <SideNavbar
          setHistoySections={setHistoySections}
          setisDefaultPage={setisDefaultPage}
          colorCodeDataItem={colorCodeDataItem}
          HistoySections={HistoySections}
          saved={saved}
          nodataImage={nodataImage}
          isLoading={isLoading}
          fetchHistoryDataItem={fetchHistoryDataItem}
          setSuccessHistoryloading={setSuccessHistoryloading}
          newChatStart={newChatStart}
          historyFlag={historyFlag}
          setHistoryFlag={setHistoryFlag}
          isDefaultPage={isDefaultPage}
          page={page}
          historyDataFlag={historyDataFlag}
          setHistoryDataFlag={setHistoryDataFlag}
        />
      </Box>
    );
  }
);

export default MainLayout;
