import React, { useState, useEffect, useRef } from "react";
import "./ChatResponse.scss";
import AnswerDisplay from "./AnswerDisplay";
import Alert from "@mui/material/Alert";
import { Avatar } from "@mui/material";
import DisplayUserInput from "./DisplayUserInput";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import PushPinIcon from "@mui/icons-material/PushPin";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PinningPopup from "../history/PinningPopup";
const loadingMessages = [
  { text: "Understanding the questions...", delay: 2000 },
  { text: "Generating SQL...", delay: 2000 },
  { text: "Synthesizing the insights...", delay: 2000 },
  { text: "Preparing reports...", delay: 2000 },
];

const ChatResponse = ({
  message,
  setIsLoading,
  isLoading,
  index,
  printPDF,
  loadingIndex,
  setResponceData,
  isHistory,
  page,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const chatEndRef = useRef(null);
  const [isPinned, setIsPinned] = useState(
    (message && message?.pin_response) || false
  );
  const [pinError, setPinError] = useState(false);
  const [pinSuccess, setPinSuccess] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const history = useHistory();

  const handlePin = async (messageId, child_question_id) => {
    let baseUrl = "";
    let data = null;
    try {
      if (!isPinned) {
        baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/pin`;
        data = {
          messageID: messageId,
          email: `${localStorage.getItem("userinfo")}`,
          child_question_id: child_question_id,
        };
      } else {
        baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/remove/pin`;
        data = {
          messageID: messageId,
          email: `${localStorage.getItem("userinfo")}`,
        };
      }
      setPinLoading(true);

      const response = await axios.post(baseUrl, data);

      if (response.status === 200) {
        setPinLoading(false);
        setPinSuccess(true);
        setTimeout(() => {
          setPinSuccess(false);
        }, 2000);
        setIsPinned((prevState) => !prevState);
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setPinLoading(false);
        setPinError(true);
        setTimeout(() => {
          setPinError(false);
        }, 2000);
      }
    } catch (error) {
      setPinLoading(false);
      console.error("Error delete history chat:", error);
      setPinError(true);
      setTimeout(() => {
        setPinError(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isLoading && loadingStep < loadingMessages.length) {
      const timer = setTimeout(() => {
        setLoadingStep((prevState) => prevState + 1);
      }, loadingMessages[loadingStep].delay);

      return () => clearTimeout(timer);
    }
  }, [isLoading, loadingStep]);

  useEffect(() => {
    if (!isLoading && page === "Home") {
      setResponceData((prevState) => {
        // Create a new array with updated loading status
        return prevState.map((item) => {
          // If the item has loading as true, set it to false
          if (item.loading) {
            return { ...item, loading: false }; // return a new object with updated loading property
          }
          return item; // return the item unchanged if loading is not true
        });
      });
    }
  }, [isLoading]);

  return (
    <>
      {page && page === "Pin" && (
        <div id={`message-${index}`} className={`query-container`}>
          <DisplayUserInput message={message} />

          <div className="answer-card">
            <div className={`answer-cont`}>
              <div className="avatar-container">
                <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                <div className="pin-cont">
                  <PushPinIcon
                    className={`pin-icon ${isPinned ? "pinned" : ""}`}
                    onClick={() => handlePin(message?.message_id)}
                  />
                </div>
              </div>
              {/* {message?.summary_of_summaries &&
                message?.summary_of_summaries.toLowerCase() !== "na" &&
                message?.summary_of_summaries.toLowerCase() !== "n/a" && (
                  <>
                    <div className="summary-section">
                      <h3>Overall Summary</h3>
                      <p>{message?.summary_of_summaries}</p>
                    </div>
                    <h3 className="supporting-txt">Supporting Facts</h3>
                  </>
                )} */}
              <div className="main-card">
                {message?.content.map((item, index) => (
                  <AnswerDisplay
                    key={item.child_question_id}
                    item={item}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    responseId={message?.message_id}
                    setOpen={setOpen}
                    open={open}
                    index={item.child_question_id}
                    responseNumber={message?.content.length}
                    isPinned={isPinned}
                    pinError={pinError}
                    handlePin={handlePin}
                    pinLoading={pinLoading}
                  />
                ))}
              </div>
              <div className="pin-message">
                {pinError && (
                  <Alert severity="error">
                    Something went wrong. please try again.
                  </Alert>
                )}
                {pinSuccess && (
                  <Alert severity="success">
                    Response unpinned successfully.
                  </Alert>
                )}
              </div>
            </div>
          </div>

          {/* {!isLoading && !message.user && message.error && (
            <div className="answer-card">
              <div className={`answer-cont`}>
                <div className="avatar-container">
                  <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                </div>
                <p className="error-message">
                  Something went wrong. Please try again.
                </p>
              </div>
            </div>
          )} */}
        </div>
      )}
      {page === "Home" &&
        (isHistory ? (
          <div id={`message-${index}`} className={`query-container`}>
            <DisplayUserInput message={message?.response} />

            <div className="answer-card">
              <div className={`answer-cont`}>
                <div className="avatar-container">
                  <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                  <div className="pin-cont">
                    <PushPinIcon
                      className={`pin-icon ${isPinned ? "pinned" : ""}`}
                      onClick={() => handlePin(message?.response?.message_id)}
                    />
                  </div>
                </div>
                {message?.response?.summary_of_summaries &&
                  message?.response?.summary_of_summaries.toLowerCase() !==
                    "na" &&
                  message?.response?.summary_of_summaries.toLowerCase() !==
                    "n/a" && (
                    <>
                      <div className="summary-section">
                        {/* <h3>Overall Summary</h3> */}
                        <p>{message?.response?.summary_of_summaries}</p>
                      </div>
                      <h3 className="supporting-txt">Supporting Facts</h3>
                    </>
                  )}
                <div className="main-card">
                  {message?.response?.content.map((item, index) => (
                    <AnswerDisplay
                      key={item.child_question_id}
                      item={item}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      responseId={message?.response.message_id}
                      setOpen={setOpen}
                      open={open}
                      index={item.child_question_id}
                      responseNumber={message?.response?.content.length}
                      isPinned={isPinned}
                      pinError={pinError}
                      handlePin={handlePin}
                      pinLoading={pinLoading}
                    />
                  ))}
                </div>
                <div className="pin-message">
                  {pinError && (
                    <Alert severity="error">
                      Something went wrong. please try again.
                    </Alert>
                  )}
                  {pinSuccess && (
                    <Alert severity="success">
                      Response pinned successfully.
                    </Alert>
                  )}
                </div>
              </div>
            </div>

            {/* {!isLoading && !message.user && message.error && (
            <div className="answer-card">
              <div className={`answer-cont`}>
                <div className="avatar-container">
                  <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                </div>
                <p className="error-message">
                  Something went wrong. Please try again.
                </p>
              </div>
            </div>
          )} */}
          </div>
        ) : (
          <div
            id={`message-${index}`}
            className={`query-container ${
              message.loading ? "full-height" : ""
            }`}
          >
            {message.user && (
              <>
                <DisplayUserInput message={message} />
                <div ref={chatEndRef} />
                {message.loading && (
                  <div className="loading-cont">
                    <img width={120} src={botImg} alt="aadi-loading" />
                    <p className="loading-text">
                      {loadingStep < loadingMessages.length
                        ? loadingMessages[loadingStep].text
                        : "Preparing reports..."}
                    </p>
                  </div>
                )}
              </>
            )}
            {!message.loading &&
              message?.content &&
              !message.user &&
              !message.error && (
                <div className="answer-card">
                  <div className={`answer-cont`}>
                    <div className="avatar-container">
                      <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                      {/* <div className="pin-cont">
                        <PushPinIcon
                          className={`pin-icon ${isPinned ? "pinned" : ""}`}
                          onClick={() => setOpen(true)}
                        />
                      </div> */}
                    </div>
                    {message?.summary_of_summaries &&
                      message?.summary_of_summaries.toLowerCase() !== "na" &&
                      message?.summary_of_summaries.toLowerCase() !== "n/a" && (
                        <>
                          <div className="summary-section">
                            {/* <h3>Overall Summary</h3> */}
                            <p>{message.summary_of_summaries}</p>
                          </div>
                          <h3 className="supporting-txt">Supporting Facts</h3>
                        </>
                      )}
                    <div className="main-card">
                      {message?.content.map((item) => (
                        <AnswerDisplay
                          key={item.child_question_id}
                          item={item}
                          setIsLoading={setIsLoading}
                          isLoading={isLoading}
                          responseId={message.message_id}
                          setOpen={setOpen}
                          open={open}
                          index={item.child_question_id}
                          printPDF={printPDF}
                          responseNumber={message?.content.length}
                          isPinned={isPinned}
                          pinError={pinError}
                          handlePin={handlePin}
                          pinLoading={pinLoading}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="pin-message">
                    {pinError && (
                      <Alert severity="error">
                        Something went wrong. please try again.
                      </Alert>
                    )}
                    {pinSuccess && (
                      <Alert severity="success">
                        {isPinned
                          ? "Response pinned successfully."
                          : "Response unpinned successfully."}
                      </Alert>
                    )}
                  </div>
                </div>
              )}
            {!isLoading && !message.user && message.error && (
              <div className="answer-card">
                <div className={`answer-cont`}>
                  <div className="avatar-container">
                    <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                  </div>
                  <p className="error-message" style={{ marginTop: "16px" }}>
                    Something went wrong. Please try again.
                  </p>
                </div>
              </div>
            )}
            {open && (
              <PinningPopup
                open={open}
                setOpen={setOpen}
                messageID={message.message_id}
                pinLoading={pinLoading}
                handlePin={handlePin}
                isPinned={isPinned}
                item={message}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default ChatResponse;
