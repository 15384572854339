import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import { useReactToPrint } from "react-to-print";
import PlotlyGrapgh from "../../components/layout/PlotlyGrapgh";
import PlotlyPopup from "./PlotlyPopup";
import { FaArrowRight } from "react-icons/fa";
import DataFrameComponent from "../../components/layout/DataFreamComponent";
import SqlQueryComponent from "../../components/layout/SqlQueryComponent";
import { Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Options from "./Options";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import FollowUpResponse from "./FollowUpResponse";
import axios from "axios";
import ScoreSection from "./ScoreSection";
import Feedback from "../feedback/Feedback";
import { FaPlus, FaMinus } from "react-icons/fa";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import "./DisplayResponse.scss";
const DisplayResponse = ({
  message,
  GeneratingResponce,
  setResponseNum,
  responseNum,
  index,
  messageID,
  isPinned,
  handlePin,
  pinError,
  version,
  item,
  pinLoading,
  setCurrentMessage,
  currentMessage,
  setFollowUp,
  followUp,
}) => {
  const getInitialFollowResponse = (followUp, message) => {
    if (Array.isArray(followUp) && followUp.length > 0) {
      return followUp;
    } else if (Array.isArray(message.followup) && message.followup.length > 0) {
      return message.followup;
    } else {
      return [];
    }
  };
  const [messageIndex, setMessageIndex] = useState(0);
  const [viewMore1, setViewMore1] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [responseversion, setresponseversion] = useState(version || 1);
  const [printIndex, setPrintIndex] = useState(null);
  const [openInput, setOpenInput] = useState(false);
  const [followInput, setFollowInput] = useState("");
  const [followResponse, setFollowResponse] = useState(
    getInitialFollowResponse(followUp, message)
  );
  const [plotlyKey, setPlotlyKey] = useState(Date.now()); // State to force re-render

  const [followLoading, setFollowLoading] = useState(false);
  const [followError, setFollowError] = useState(false);
  const componentRef1 = useRef();
  const [HistoryTriggered, setHistoryTrigger] = useState(false);
  const [plotlyGraph,setPlotlyGraph] = useState(message.figure)

  const [currentMessageId, setCurrentMessageId] = useState(() => {
    if (message.followup && message.followup.length > 0) {
      let followUpLength = message.followup.length;
      return message.followup[followUpLength - 1].message_id;
    } else {
      return messageID;
    }
  });

  const [currentChlldId, setcurrentChildId] = useState(() => {
    if (message.followup && message.followup.length > 0) {
      return "1";
    } else {
      return message.child_question_id;
    }
  });

  const [openPlotly, setOpenPlotly] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => {
      if (printIndex !== null && componentRef1.current) {
        const printRef = componentRef1.current;
        const clonedElement = printRef.cloneNode(true);
        const PrintElem = document.createElement("div");
        const header = "";
        PrintElem.innerHTML = header;
        PrintElem.appendChild(clonedElement);
        return PrintElem;
      }
      return null;
    },
    onAfterPrint: () => {
      setPrintIndex(null);
    },
  });

  const printPDF = (index) => {
    setPrintIndex(true);
  };
  useEffect(() => {
    if (printIndex) {
      handlePrint();
    }
  }, [printIndex]);
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red"; // Default to red if the score is not a number
    }
    return parsedScore >= 0.75
      ? "green"
      : parsedScore >= 0.5
      ? "#FFC000"
      : "red";
  };
  const backgroundColor = getBackgroundColor(message.confidence_score);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (followInput === "") return false;
    setFollowLoading(true);
    setFollowError(false);
    setFollowResponse((prevState) => [
      ...prevState,
      {
        query: followInput,
        user: true,
        loading: true,
      },
    ]);

    const dataItem = {
      query: followInput,
      Response_ID: currentMessageId || messageID,
      Section_ID: currentChlldId + "",
    };
    const baseurl = `${process.env.REACT_APP_PYTHON_BACKEND_URL}/ask_followup`;
    try {
      const response = await axios.post(baseurl, dataItem);
      const data = response.data;

      if (response.status === 200) {
        const botResponse = data.response;

        // if(typeof(data) === 'string'){
        //   const trimData = JSON.parse(data)
        //   console.log(trimData)
        //     botResponse = trimData.response;
        //     console.log(botResponse)

        // }
        setFollowResponse((prevState) => [
          ...prevState,
          { ...botResponse, user: false },
        ]);
        if (botResponse?.message_id) {
          setCurrentMessageId(botResponse.message_id);
          setcurrentChildId("1");
        }

        // setCurrentMessageId(botResponse)
        setFollowLoading(false);
        setFollowError(false);
        setHistoryTrigger(true);
      } else {
        setFollowLoading(false);
        setFollowError(true);
      }
    } catch (err) {
      setFollowLoading(false);
      setFollowError(true);
      console.log("error occurred", err);
    }

    setFollowInput("");
  };

  const SaveFollowUpHistory = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/history/followup`;

    const HistoryDataSave = [
      {
        MessageID: messageID,
        email: localStorage.getItem("userinfo"),
        child_response_id: message.child_question_id,
        data: followResponse,
      },
    ];

    try {
      const response = await axios.post(baseUrl, HistoryDataSave);
      if (response.status === 200) {
        console.log("history saved successfully");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  useEffect(() => {
    if (HistoryTriggered) {
      SaveFollowUpHistory();
      setTimeout(() => {
        setHistoryTrigger(false);
      }, 2000);
    }
  }, [HistoryTriggered]);

  useEffect(() => {
    if (followResponse && followResponse.length > 0) {
      setFollowUp(followResponse);
    }
  }, [followResponse]);



  return (
    <>
      <div
        style={{
          // display: "grid",
          gap: "1rem",
          width: "100%",
        }}
        ref={componentRef1}
      >
        <div className="response-item" id="response-1">
          <>
            <div>
              {/* <img alt="gif-responce" width={50} src={botImg} /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  {message.confidence_score !== 0 && (
                    <div
                      style={{
                        backgroundColor: backgroundColor,
                        color:
                          parseFloat(message.confidence_score).toFixed(2) >=
                          0.75
                            ? "white"
                            : parseFloat(message.confidence_score).toFixed(2) >=
                              0.5
                            ? "black"
                            : "white",
                        padding: "7px",
                        borderRadius: "6px",
                        fontWeight: "600",
                      }}
                    >
                      {parseFloat(message.confidence_score).toFixed(2)}
                    </div>
                  )}
                  <div
                    className={`three-dots-icon ${
                      openOptions ? "option-active" : ""
                    }`}
                    onClick={() => {
                      setOpenOptions((prevState) => true);
                    }}ƒ
                  >
                    <MoreVertIcon />
                  </div>
                </div>
              </div>

              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  paddingBottom: "10px",
                  paddingTop: "20px",
                }}
              >
                {message.header ? message.header : ""}
              </div>

              <span
                style={{
                  fontSize: "16px",
                }}
                className="responce-summary"
              >
                {message.text === "" ? (
                  "N/A"
                ) : (
                  <ReactMarkdown>{message.summary}</ReactMarkdown>
                )}
              </span>
            </div>
          </>

          {message.figure && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
              className="plotly-cont"
            >
              {/* <div
                className="plotly-item-container"
                onClick={() => setOpenPlotly(true)}
              >
                <OpenInFullIcon />
              </div> */}
              <PlotlyGrapgh
                graph={plotlyGraph}
                messageID={messageID}
                childId={item.child_question_id}
                version={responseversion}
                tableCoumn={message.dataframe}

              />
            </div>
          )}

          <div>
            <>
              {
                <>
                  <div
                    className="HideMoreInPrints"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%",
                      padding: "16px 0px ",
                    }}
                  >
                    <span
                      style={{
                        // paddingRight: "1rem",
                        // color: "#fff",
                        // fontWeight: "600",
                        // cursor: "pointer",
                        // backgroundColor: "#bb1ccc",
                        // padding: "10px 6px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className={``}
                      onClick={() => {
                        setViewMore1(!viewMore1);
                        // setResponseNum(1);
                      }}
                    >
                      {/* {viewMore1 ? <KeyboardArrowUpIcon /> + 'Explian' :  <KeyboardArrowDownIcon />} */}
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "600",
                          display: "flex",
                          fontSize: "18px",
                          color: "#bb1ccc",
                        }}
                      >
                        {viewMore1 ? (
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <KeyboardArrowDownIcon />
                            <span> View Less</span>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <KeyboardArrowRightIcon />
                            <span>Explain</span>
                          </div>
                        )}
                      </p>
                    </span>

                    {/* {!viewMore1 &&
                      followResponse.length === 0 &&
                      !openInput && (
                        <button
                          onClick={() => setOpenInput(!openInput)}
                          style={{ borderRadius: "4px" }}
                          className={`submit_btn`}
                        >
                          Follow Up
                        </button>
                      )} */}
                  </div>
                </>
              }
            </>

            {viewMore1 && (
              <>
                {message.dataframe && (
                  <DataFrameComponent message={message.dataframe} />
                )}

                {message.sql && (
                  <>
                    <div>
                      <SqlQueryComponent sqlQuery={message.sql} index="1" />
                    </div>
                  </>
                )}
              </>
            )}
            {viewMore1 && <ScoreSection allScore={message?.all_score} />}

            <div className="HideMoreInPrints">
              <>
                {message?.LastUpdate && (
                  <div
                    style={{
                      color: "#333",
                      fontWeight: "600",
                      fontSize: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    Last Refresh: {formatDateString(message?.LastUpdate)}
                  </div>
                )}
                <Feedback
                  feedbackStatus={message.feedback ? message.feedback : ""}
                  messageID={messageID}
                  index={index}
                  child_response_id={item.child_question_id}
                />
              </>
            </div>
            {!followError &&
              followResponse.map((response, index) => (
                <FollowUpResponse
                  key={index}
                  response={response}
                  followLoading={followLoading}
                  setFollowResponse={setFollowResponse}
                />
              ))}

            {followError && (
              <div className="follow-error">
                <p>Something Went Wrong. Please try again.</p>
              </div>
            )}
            {/* {viewMore1 && !openInput && (
              <button
                onClick={() => setOpenInput(!openInput)}
                style={{ borderRadius: "4px", marginTop: "16px" }}
                className={`submit_btn`}
              >
                Follow Up
              </button>
            )} */}

            <div className="follow-up-cont" style={{ marginTop: "24px" }}>
              {" "}
              <form onSubmit={(e) => handleFormSubmit(e)} className="chat-form">
                <div className="input-group mt-0 mb-0">
                  <input
                    // ref={inputRef}
                    id="chat_pdf_input"
                    placeholder="Ask a follow up question ..."
                    className="message-type-box form-control border-0"
                    type="text"
                    autoComplete="off"
                    value={followInput}
                    onChange={(e) => setFollowInput(e.target.value)}
                    // onKeyDown={handleKeyPress}
                  />
                  <Button
                    id="send_pdf_chat"
                    className="btn btn-light-success text-success sendbtn borderColorNew"
                    type="submit"
                    disabled={followLoading}
                  >
                    {followLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      <SendIcon
                        style={{ color: "#bb1ccc" }}
                        className="colorCss"
                      />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
          {openOptions && (
            <Options
              printPDF={printPDF}
              index={index}
              setOpenOptions={setOpenOptions}
              messageID={messageID}
              isPinned={isPinned}
              pinError={pinError}
              handlePin={handlePin}
              pinLoading={pinLoading}
              item={item}
            />
          )}

        
        </div>
      </div>
    </>
  );
};

export default DisplayResponse;
