import React, { useEffect, useState, useRef } from "react";
import botImg from "../../app/assets/videos/Loading-gif.gif";

import { Avatar } from "@mui/material";

import "./FollowUpResponse.scss";
import FollowUpDisplay from "./FollowUpDisplay";

const loadingMessages = [
  { text: "Understanding the questions...", delay: 2000 },
  { text: "Generating SQL...", delay: 2000 },
  { text: "Synthesizing the insights...", delay: 2000 },
  { text: "Preparing reports...", delay: 2000 },
];

const FollowUpResponse = ({ response, followLoading, setFollowResponse, page }) => {
  const [loadingStep, setLoadingStep] = useState(0);
  const scrollRef = useRef();
  useEffect(() => {
    if (followLoading && loadingStep < loadingMessages.length) {
      const timer = setTimeout(() => {
        setLoadingStep((prevState) => prevState + 1);
      }, loadingMessages[loadingStep].delay);

      return () => clearTimeout(timer);
    }
  }, [followLoading, loadingStep]);

  useEffect(() => {
    if (!followLoading && page!== 'favorite') {
      setFollowResponse((prevState) => {
        // Create a new array with updated loading status
        return prevState.map((item) => {
          // If the item has loading as true, set it to false
          if (item.loading) {
            return { ...item, loading: false }; // return a new object with updated loading property
          }
          return item; // return the item unchanged if loading is not true
        });
      });
    }
  }, [followLoading]);

  const scrollToRef = () => {
    // Use scrollIntoView with the desired options to center the element
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    scrollToRef();
  }, [response?.query]);

  return (
    <div
      style={{
        // display: "grid",
        gap: "1rem",
        width: "100%",
      }}
    >
      <div
        className={`${response?.user ? "" : "response-item"}`}
        id="follow-response"
      >
        {response?.user && (
          <div className="query-section">
            <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
            <p className="follow-query">{response.query}</p>
          </div>
        )}
        <div ref={scrollRef}></div>
        {response.user && page!=='favorite' && (
          <>
            {response.loading && (
              <div className={`loading-cont-follow ${response.loading ? 'fullHeightFollow' : ''}`}>
                <img width={90} src={botImg} alt="aadi-loading" />
                <p className="loading-text">
                  {loadingStep < loadingMessages.length
                    ? loadingMessages[loadingStep].text
                    : "Preparing reports..."}
                </p>
              </div>
            )}
          </>
        )}

        {/* <img alt="gif-responce" width={50} src={botImg} /> */}
        {!response?.user &&
          response?.content &&
          response?.content.map((content, index) => (
            <FollowUpDisplay
              key={index}
              message={content}
              messageID={response.message_id}
            />
          ))}
      </div>
    </div>
  );
};

export default FollowUpResponse;
