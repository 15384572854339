import { Avatar } from "@mui/material";
import React from "react";
import Getusers from "../../app/auth/Getusers";
import { useMediaQuery } from "react-responsive";

const DisplayUserInput = ({ message }) => {
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });
  function isObject(variable) {
    return variable !== null && typeof variable === 'object';
}
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          alignContent: "center",
          background: "#eee",
          padding:isTab? "0.1rem":"1rem",
          borderRadius: "10px",
          paddingRight: "2.5rem",
        }}
        className="mobile-layout-user-query"
      >
        <span>
          <Avatar
            sx={{
              background: "#bb1ccc",
              width: "30px",
              height: "30px",
              fontSize: "12px",
            }}
          >
            <Getusers className="trimCls" trim={true} />
          </Avatar>
        </span>
        <span
          style={{
            fontSize:isTab?"12px": "16px",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          {/* {isObject(message) ? message.text : message} */}
          {message.query ? message.query : message.text}
        </span>
      </div>
    </div>
  );
};

export default DisplayUserInput;
