import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ReactMarkdown from "react-markdown";
import Alert from "@mui/material/Alert";
import "./WorkHeader.scss";

const WorkHeader = ({ header }) => {
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [editableHeader, setEditableHeader] = useState(header); // State to hold the editable header
  const [currentSummary, setCurrentSummary] = useState(header);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summarySuccess, setSummarySuccess] = useState(false);

  // Function to handle the click on the edit icon
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Function to handle the change in textarea
  const handleTextareaChange = (event) => {
    setEditableHeader(event.target.value);
  };

  // Function to handle the click on the save button
  const handleSaveClick = () => {
    setSummaryLoading(true);
    setTimeout(() => {
      setSummaryLoading(false);
      setCurrentSummary(editableHeader);
      setIsEditing(false);
      setSummarySuccess(true);
    }, 2000);

    setTimeout(() => {
      setSummarySuccess(false);
    }, 6000);
  };

  // Function to handle the click on the cancel button
  const handleCancelClick = () => {
    setEditableHeader(currentSummary); // Revert changes
    setIsEditing(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {isEditing ? (
        <div>
          <textarea
            value={editableHeader}
            onChange={handleTextareaChange}
            style={{ width: "100%", height: "200px" }}
          />
          <div style={{ marginTop: "10px" }}>
            <button
              onClick={handleSaveClick}
              className="submit_btn work-bench-save"
              style={{ marginRight: "10px" }}
            >
              {summaryLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span style={{ paddingLeft: "10px" }}>Saving ...</span>
                </>
              ) : (
                "Save"
              )}
            </button>
            <button
              onClick={handleCancelClick}
              className="cancel-btn work-bench-cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="work-header-cont">
          <div>
            <ReactMarkdown>{editableHeader}</ReactMarkdown>
          </div>
          <EditIcon
            style={{ width: "6%", cursor: "pointer" }}
            onClick={handleEditClick}
          />
        </div>
      )}
      {summarySuccess && (
        <Alert
          style={{
            position: "absolute",
            top: "-60px",
            left: "40%",
            width: "25%",
          }}
          severity="success"
        >
          Summary updated successfully.
        </Alert>
      )}
    </div>
  );
};

export default WorkHeader;
