import React, { useState } from "react";
import Plot from "react-plotly.js";

const FavoritePlot = ({ graph}) => {
  const [GraphData, setGraphData] = useState(graph);
  if (Object.keys(GraphData).length === 0) {
    return <div style={{ display: "none" }}></div>;
  }

  const graphData = JSON.parse(GraphData);

  return (
    <div>
      <Plot data={graphData.data} layout={graphData.layout} />
      
    </div>
  );
};

export default FavoritePlot;
