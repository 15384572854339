import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useReactToPrint } from "react-to-print";
import FavoritePlot from "./FavoritePlot";
import { FaArrowRight } from "react-icons/fa";
import DataFrameComponent from "../../components/layout/DataFreamComponent";
import SqlQueryComponent from "../../components/layout/SqlQueryComponent";
import { Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Options from "./FavDisplayOptions";
import ScoreSection from "../../components/response/ScoreSection";
import FollowUpResponse from "../../components/response/FollowUpResponse";
import Feedback from "../../components/feedback/Feedback";
import { FaPlus, FaMinus } from "react-icons/fa";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const FavDisplayResponse = ({
  message,
  GeneratingResponce,
  setResponseNum,
  responseNum,
  index,
  messageID,
  isPinned,
  handlePin,
  pinError,
  version,
  pinLoading,
  item,
}) => {
  const [viewMore1, setViewMore1] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [responseversion, setresponseversion] = useState(version || 1);
  const [printIndex, setPrintIndex] = useState(null);
  const [openInput, setOpenInput] = useState(false);
  const componentRef1 = useRef();

  const handlePrint = useReactToPrint({
    content: () => {
      if (printIndex !== null && componentRef1.current) {
        const printRef = componentRef1.current;
        const clonedElement = printRef.cloneNode(true);
        const PrintElem = document.createElement("div");
        const header = "";
        PrintElem.innerHTML = header;
        PrintElem.appendChild(clonedElement);
        return PrintElem;
      }
      return null;
    },
    onAfterPrint: () => {
      setPrintIndex(null);
    },
  });

  const printPDF = (index) => {
    setPrintIndex(true);
  };
  useEffect(() => {
    if (printIndex) {
      handlePrint();
    }
  }, [printIndex]);
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red"; // Default to red if the score is not a number
    }
    return parsedScore >= 0.75
      ? "green"
      : parsedScore >= 0.5
      ? "#FFC000"
      : "red";
  };
  const backgroundColor = getBackgroundColor(message.confidence_score);

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  return (
    <>
      <div
        style={{
          // display: "grid",
          gap: "1rem",
          width: "100%",
        }}
        ref={componentRef1}
      >
        <div className="response-item" id="response-1">
          <>
            <div>
              {/* <img alt="gif-responce" width={50} src={botImg} /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ background: "#bb1ccc" }}>A</Avatar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  {message.confidence_score !== undefined &&
                    message.confidence_score !== "NA" && (
                      <div
                        style={{
                          backgroundColor: backgroundColor,
                          color:
                            parseFloat(message.confidence_score).toFixed(2) >=
                            0.75
                              ? "white"
                              : parseFloat(message.confidence_score).toFixed(
                                  2
                                ) >= 0.5
                              ? "black"
                              : "white",
                          padding: "7px",
                          borderRadius: "6px",
                          fontWeight: "600",
                        }}
                      >
                        {parseFloat(message.confidence_score).toFixed(2)}
                      </div>
                    )}
                  <div
                    className={`three-dots-icon ${
                      openOptions ? "option-active" : ""
                    }`}
                    onClick={() => {
                      setOpenOptions((prevState) => true);
                    }}
                  >
                    <MoreVertIcon />
                  </div>
                </div>
              </div>

              <div
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  paddingBottom: "10px",
                  paddingTop: "20px",
                }}
              >
                {message.header ? message.header : ""}
              </div>

              <span
                style={{
                  fontSize: "16px",
                }}
              >
                <ReactMarkdown className="summary-text">
                  {item.summary.toLowerCase() === "na"
                    ? message.message
                    : message.summary}
                </ReactMarkdown>
              </span>
            </div>
          </>

          {message.figure && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
              className="plotly-cont"
            >
              <FavoritePlot
                graph={message.figure}
                messageID={messageID}
                childId={index}
                version={responseversion}
              />
            </div>
          )}

          <div>
            <>
              {
                <>
                  <div
                    className="HideMoreInPrints"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%",
                      padding: "16px 0px ",
                    }}
                  >
                    <span
                      style={{
                        // paddingRight: "1rem",
                        // color: "#fff",
                        // fontWeight: "600",
                        // cursor: "pointer",
                        // backgroundColor: "#bb1ccc",
                        // padding: "10px 6px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className={``}
                      onClick={() => {
                        setViewMore1(!viewMore1);
                        // setResponseNum(1);
                      }}
                    >
                      {/* {viewMore1 ? <KeyboardArrowUpIcon /> + 'Explian' :  <KeyboardArrowDownIcon />} */}
                      <p
                        style={{
                          margin: "0",
                          fontWeight: "600",
                          display: "flex",
                          fontSize: "18px",
                          color: "#bb1ccc",
                        }}
                      >
                        {viewMore1 ? (
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <KeyboardArrowDownIcon />
                            <span> View Less</span>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <KeyboardArrowRightIcon />
                            <span>Explain</span>
                          </div>
                        )}
                      </p>
                    </span>
                  </div>
                </>
              }
            </>

            {viewMore1 && (
              <>
                {message.dataframe && (
                  <DataFrameComponent message={message.dataframe} />
                )}

                {message.sql && (
                  <>
                    <div>
                      <SqlQueryComponent sqlQuery={message.sql} index="1" />
                    </div>
                  </>
                )}
              </>
            )}

            {viewMore1 && <ScoreSection allScore={message?.all_score} />}

            <div className="HideMoreInPrints">
              <>
                {message?.LastUpdate && (
                  <div
                    style={{
                      color: "#333",
                      fontWeight: "600",
                      fontSize: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    Last Refresh: {formatDateString(message?.LastUpdate)}
                  </div>
                )}
                <Feedback
                  feedbackStatus={message.feedback ? message.feedback : ""}
                  messageID={messageID}
                  index={index}
                  child_response_id={index}
                />
              </>
            </div>

            {item.followup &&
              item.followup.length > 0 &&
              item.followup.map((response, index) => (
                <FollowUpResponse
                  key={index}
                  response={response}
                  page="favorite"
                />
              ))}
          </div>
          {openOptions && (
            <Options
              printPDF={printPDF}
              index={index}
              setOpenOptions={setOpenOptions}
              messageID={messageID}
              isPinned={isPinned}
              pinError={pinError}
              handlePin={handlePin}
              pinLoading={pinLoading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FavDisplayResponse;
