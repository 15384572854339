import React, { useState } from "react";
import "./MessagePopup.scss";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FavDisplayResponse from "./FavDisplayResponse";
import { useMediaQuery } from 'react-responsive';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const MessagePopup = ({
  open,
  setOpen,
  message,
  index,
  printPDF,
  messageID,
  isPinned,
  handlePin,
  pinError,
  version,
  pinLoading,
  item
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      id="message-popup"
    >
      {/* <DialogTitle>{"Why did you choose this rating? (optional)"}</DialogTitle> */}
      {
        isMobile && (
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        )
      }
      <DialogContent>
        {open && (
          <FavDisplayResponse
            message={message}
            index={message.child_question_id}
            printPDF={printPDF}
            version={version}
            messageID={messageID}
            isPinned={isPinned}
            pinError={pinError}
            handlePin={handlePin}
            pinLoading={pinLoading}
            item={item}
          />
        )}
      </DialogContent>

    </Dialog>
  );
};

export default MessagePopup;
