import React, { useEffect, useState } from "react";
import MessagePopup from "../response/MessagePopup";
import "./HistoryAnswerDisplay.scss";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactMarkdown from "react-markdown";
import PlotlyGraph from "../layout/PlotlyGrapgh";
import Feedback from "../feedback/Feedback";
import PushPinIcon from "@mui/icons-material/PushPin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryQueryOptions from "./HistoryQueryOptions";
import { useMediaQuery } from "react-responsive";
import $ from "jquery";

const HistoryAnswerDisplay = ({
  item,
  setIsLoading,
  isLoading,
  index,
  printPDF,
  responseNumber,
  responseId,
  handlePin,
  pinError,
  isPinned,
  message,
  pinLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [followUp, setFollowUp] = useState([]);

  const handleExpandClick = (item) => {
    setCurrentMessage(item);
    setOpen(true);
  };
  function trimString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str + " ...";
  }
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red";
    }
    return parsedScore > 0.75
      ? "green"
      : parsedScore > 0.5
      ? "#FFC000"
      : "red";
  };
  const backgroundColor = getBackgroundColor(item.confidence_score);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  useEffect(() => {
    $(".summary-text").each(function () {
      // Select all <p> tags inside current .summary-text element except the first one
      // $(this).find('p:not(:first-child)').remove();
      const paragraphs = $(this).find("p");
      $(this).children(":not(p, pre)").remove();

      // Check number of <p> tags
      if (paragraphs.length > 1) {
        // Remove all <p> tags except the first one
        paragraphs.slice(1).remove();
      }
    });
  }, []);

  return (
    <div
      className="response-card"
      style={
        {
          // overflowX: isTab ? "auto" : "visible",
          // whiteSpace: isTab ? "nowrap" : "normal",
        }
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent:
            item.header && isTab ? "space-between" : "space-between",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "10px",
        }}
        className="mobile-header-layout"
      >
        <div
          className="mobileLayout-width"
          style={{ width: isTab ? "60%" : "80%" }}
        >
          <p className="response-header">{item.header ? item.header : ""}</p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
          className="mobile-score-layout"
        >
          {item.confidence_score !== undefined &&
            item.confidence_score !== "NA" && (
              <div
                style={{
                  backgroundColor: backgroundColor,
                  color:
                    parseFloat(item.confidence_score).toFixed(2) > 0.75
                      ? "white"
                      : parseFloat(item.confidence_score).toFixed(2) > 0.5
                      ? "black"
                      : "white",
                  padding: "7px",
                  borderRadius: "6px",
                  fontWeight: "600",
                }}
              >
                {parseFloat(item.confidence_score).toFixed(2)}
              </div>
            )}

          <div
            className={`three-dots-icon ${openOptions ? "option-active" : ""}`}
            onClick={() => {
              setOpenOptions((prevState) => true);
            }}
          >
            <MoreVertIcon />
          </div>
        </div>
      </div>
      <ReactMarkdown className="summary-text">
        {item.summary.toLowerCase() === "na"
          ? item.message
          : responseNumber > 1
          ? trimString(item.summary, 600)
          : item.summary}
      </ReactMarkdown>
      {item.summary && item.summary.toLowerCase() !== "na" && (
        <p className="read-more-btn" onClick={() => handleExpandClick(item)}>
          Read More
        </p>
      )}
      {item.figure && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            marginTop: "16px",
          }}
          className="plotly-cont"
        >
          <PlotlyGraph
            graph={item.figure}
            childId={item.child_question_id}
            version={item.version ? item.version : 1}
            AnswerDisplay="AnswerDisplay"
            messageID={responseId}
            tableCoumn={item.dataframe}
          />
        </div>
      )}
      <div className="HideMoreInPrints">
        <>
          {item?.LastUpdate && (
            <div
              style={{
                color: "#333",
                fontWeight: "600",
                fontSize: "12px",
                marginBottom: "12px",
              }}
            >
              Last Refresh: {formatDateString(item?.LastUpdate)}
            </div>
          )}
          <Feedback
            feedbackStatus={item.feedback ? item.feedback : ""}
            messageID={responseId}
            index={index}
            child_response_id={index}
          />
        </>
      </div>
      <MessagePopup
        open={open}
        setOpen={setOpen}
        message={currentMessage}
        index={index}
        printPDF={printPDF}
        messageID={responseId}
        isPinned={isPinned}
        pinError={pinError}
        handlePin={handlePin}
        version={item.version ? item.version : 1}
        pinLoading={pinLoading}
        item={item}
        followUp={followUp}
        setFollowUp={setFollowUp}
      />{" "}
      {/* Pass the current message to the popup */}
      {openOptions && (
        <HistoryQueryOptions
          handleExpandClick={handleExpandClick}
          setOpenOptions={setOpenOptions}
          isPinned={isPinned}
          pinError={pinError}
          handlePin={handlePin}
          item={item}
          messageID={responseId}
          pinLoading={pinLoading}
        />
      )}
    </div>
  );
};

export default HistoryAnswerDisplay;
