import React from "react";
import "./FavChatDisplay.scss";
import FavResponse from "./FavResponse";

const FavoriteDisplay = ({
  setIsLoading,
  isLoading,
  responceData,
  query,
  printPDF,
  loadingIndex,
  setResponceData,
  isHistory,
  page,
  fetchpinData
}) => {


  return (
    <div className="response-cont">
      {responceData.map((message, index) => (
        <FavResponse
          key={index}
          message={message}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          index={index}
          printPDF={printPDF}
          loadingIndex={loadingIndex}
          setResponceData={setResponceData}
          isHistory={isHistory}
          page={page}
          fetchpinData={fetchpinData}
        />
      ))}
    </div>
  );
};

export default FavoriteDisplay;
