import React, { forwardRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import './TestDataPopup.scss'

// Transition component for the dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// UnpinPopup functional component
const UnpinPopup = ({open, setOpen, addTestDataLoading, addDataHandler}) => {
 
  if (!open) return null; // Only render component if `open` is true



  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      id="unpin-dialog"
    >
      <DialogTitle
        style={{ fontWeight: "600", color: "#fff", background: "#bb1ccc" }}
      >
      Add to Test Data
      </DialogTitle>
      <DialogContent>
        <div style={{ fontSize: "18px", paddingTop: "24px", color: "#212121" }}>
         Are you sure, you want to add it to Test Data?
        </div>
      </DialogContent>
      <DialogActions style={{ marginRight: "1rem", marginBottom: "5px" }}>
        <Button
          style={{ width: "auto" }}
          className="cancel-btn"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ color: "white", width: "auto" }}
          className="submit_btn work-submit-btn"
          onClick={() => {
            addDataHandler()
          }}
        >
          {addTestDataLoading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span style={{ paddingLeft: "10px" }}>Adding Data ...</span>
            </>
          ) :
            "Add Data"
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnpinPopup;
