import React, { useRef, useEffect, useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import DownloadIcon from "@mui/icons-material/Download";
import PinningPopup from "../history/PinningPopup";
import "./Options.scss";

// const optionsItems = [
//   { id: 1, label: "Pin", icon: <PushPinIcon /> },
//   { id: 2, label: "Download Pdf", icon: <DownloadIcon /> },
// ];
const Options = ({
  setOpenOptions,
  printPDF,
  index,
  messageID,
  isPinned,
  handlePin,
  pinError,
  item,
  pinLoading,
}) => {
  const [open, setOpen] = useState(false);
  const optionsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    if (!open) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [open]);

  useEffect(() => {
    if (!pinLoading) {
      setOpen(false);
    }
  }, [pinLoading]);

  return (
    <div
      className={`${"options-container"}
      }`}
      ref={optionsRef}
    >
      <ul className="options-list">
        <li className="list-item">
          <div onClick={() => setOpen(true)} className="item-container">
            <PushPinIcon className={`${isPinned ? "active-pin" : ""}`} />
            <p className="setting-txt">{isPinned ? "Unpin" : "Pin"}</p>
          </div>
        </li>
        <li
          className="list-item"
          onClick={() => {
            setOpenOptions(false);
            printPDF(index);
          }}
        >
          <div className="item-container">
            <DownloadIcon />
            <p className="setting-txt">Download Pdf</p>
          </div>
        </li>
        {open && (
          <PinningPopup
            open={open}
            setOpen={setOpen}
            messageID={messageID}
            pinLoading={pinLoading}
            handlePin={handlePin}
            isPinned={isPinned}
            item={item}
          />
        )}
      </ul>
    </div>
  );
};

export default Options;
