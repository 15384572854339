import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, powerbi } from "powerbi-client";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));




const Dashboard = () => {

  const [powerbiReport,setPowerbiReport] = useState({})
  const [loading,setLoading] = useState(true)
  const [powerbiLoading,setpowerbiLoading] = useState(true)

  const powerbiReportData = async () =>{
    try {
      const baseurl = `${process.env.REACT_APP_BASE_URL}/aadi/report?reportId=101`
      
     
      const response = await axios.get(baseurl);
      
      // Handling the response
      if (response.status === 200) {
        console.log('token generated successfully:', response.data);
        setLoading(false)
        setPowerbiReport(response.data)
        return response.data;
      } else {
        console.error('Error posting FAQ data:', response.status, response.statusText);
        setLoading(false)

      }
    } catch (error) {
      console.error('Error posting FAQ data:', error);
      setLoading(false)

    }
  }

  useEffect(()=>{
    powerbiReportData()
  },[])

const embdData = (embeddedReport) => {
  window.report = embeddedReport;
  window.report.on("loaded", async function () {
    var reportPages = await window.report.getPages();


  })

}


const token = powerbiReport.embedToken
const url = powerbiReport.reportURL
const reportGId = powerbiReport.reportGuid


  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div
              style={{
                gridGap: "10px",
                justifyContent: "flex-start",
                width: "100%",
                textAlign: "left",
                height: "calc(100vh - 100px)",
                position:"relative",
                overflow: "hidden",
                overflowY: powerbiLoading ? "none" : "auto",

              }}
            >
              <div style={{height:"100%"}}>

                {powerbiLoading && (
                  <div style={{position:"absolute",background:"white",height:"100%",left:'0',right:"0",top:"0",bottom:'0',overflow:"hidden"}}>
                    <div style={{display:"grid",justifyContent:"center",alignContent:"center",alignItems:"center",height:"100%",overflow:"hidden",gap:"1rem"}}>
                    <div id="typingid">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>

                      <span>Please wait...</span>
                      </div>
                  </div>
                )}

            {!loading && (
                  <>
                     <PowerBIEmbed
                  embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: reportGId,
                    embedUrl: url,
                    accessToken: token,
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false
                        }
                      },
                      background: models.BackgroundType.Transparent,
                    }
                  }}

                    eventHandlers = {
                      new Map([
                        ['loaded', function () {
                          console.log('Report loaded');
                          setpowerbiLoading(false)
                        }],
                        ['rendered', function () {console.log('Report rendered');}],
                        ['error', function (event) {console.log(event.detail);}],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                      ])
                    }

                    cssClassName={"report-style-class"}
                    getEmbeddedComponent={embdData}
    
                        />
                  </>
                )}

           

              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;