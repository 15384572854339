import React, { useRef, useEffect, useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import DownloadIcon from "@mui/icons-material/Download";
import UnpinPopup from "./UnpinPopup";
import "./FavDisplayOptions.scss";

// const optionsItems = [
//   { id: 1, label: "Pin", icon: <PushPinIcon /> },
//   { id: 2, label: "Download Pdf", icon: <DownloadIcon /> },
// ];
const Options = ({
  setOpenOptions,
  printPDF,
  index,
  messageID,
  isPinned,
  handlePin,
  pinError,
  pinLoading
}) => {
  const [open, setOpen] = useState(false);
  const optionsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    if (!open) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [open]);

  return (
    <div
    id='fav-display-options'
      className={`${"options-container"}
      }`}
      ref={optionsRef}
    >
      <ul className="options-list">
        <li className="list-item">
          <div onClick={() => setOpen(true)} className="item-container">
            <PushPinIcon className={`${isPinned ? "active-pin" : ""}`} />
            <p className="setting-txt">{isPinned ? "Unpin" : "Pin"}</p>
          </div>
        </li>
        <li
          className="list-item"
          onClick={() => {
            setOpenOptions(false);
            printPDF(index);
          }}
        >
          <div className="item-container">
            <DownloadIcon />
            <p className="setting-txt">Download Pdf</p>
          </div>
        </li>
      </ul>
      {open && (
        <UnpinPopup
          open={open}
          setOpen={setOpen}
          messageID={messageID}
          pinLoading={pinLoading}
          handlePin={handlePin}
        />
      )}
    </div>
  );
};

export default Options;
