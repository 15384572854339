import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axios from "axios";
import "./Favorite.scss";
import FavoriteDisplay from "../Favorite/FavoriteDisplay";
import "./Favorite.scss";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import { useHistory } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Favorite = () => {
  const [fetchData, setFetchData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchpinData();
    localStorage.setItem("activeItem", null);
  }, []);

  const fetchpinData = async () => {
    setDataError(false);
    setDataLoading(true);
    const queryparameter = `?email=` + localStorage.getItem("userinfo");

    const baseurl =
      `${process.env.REACT_APP_BASE_URL}aadi/fetch/pin` + queryparameter;

    try {
      const response = await axios.get(baseurl);

      if (response.status === 200) {
        const dataList = response.data;
        const responcedata =
          dataList &&
          dataList.map((item) => {
            return item.response;
          });

        const sortedData = responcedata.sort((a, b) => {
          const dateA = new Date(a.content[0].LastUpdate);
          const dateB = new Date(b.content[0].LastUpdate);
          return dateB - dateA; // Sort in descending order
        });
        setFetchData(sortedData);
        setDataLoading(false);
      } else {
        console.error(
          "Error delete history data:",
          response.status,
          response.statusText
        );
        setDataLoading(false);
        setDataError(true);
      }
    } catch (error) {
      setDataLoading(false);
      setDataError(true);
      console.error("Error delete history chat:", error);
    }
  };

  const isTab = useMediaQuery({ query: "(max-width: 768px)" });
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', appHeight)
  useEffect(() => {
    appHeight()
  }, [])

  return (
    <div id="homePage"
      style={{ overflow: "hidden", height: "var(--app-height)" }}
    >
      <Box className="mainLayout" sx={{
        display: "flex",
        //  overflow: "hidden"
        flexDirection: isTab ? "column" : "",
        overflowWrap: isTab ? "break-word" : "",

      }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{ overflowY: "none" }} id="mainFavorite">
            <div
              className="pinCls"
              style={{ display: "grid", justifyContent: "flex-start" }}
            >
              {!dataLoading && !dataError && (
                <FavoriteDisplay
                  fetchpinData={fetchpinData}
                  responceData={fetchData}
                />
              )}
            </div>
            {dataLoading && (
              <div className="loading-cont">
                {" "}
                <img width={120} src={botImg} alt="aadi-loading" />
                <p style={{ display: isTab ? "inline-block" : " ", whiteSpace: isTab ? "nowrap" : " " }}>Fetching Pinned Queries ...</p>
              </div>
            )}
            {!dataLoading && dataError && (
              <div className="loading-cont">
                <p>
                  Something Went Wrong.{" "}
                  <span
                    onClick={() => window.location.reload()}
                    className="try-again-btn"
                  >
                    Please try again.
                  </span>{" "}
                </p>
              </div>
            )}

            {!dataLoading &&
              !dataError &&
              fetchData &&
              fetchData.length === 0 && (
                <div className="loading-cont">
                  <p style={{ fontSize: "18px", textAlign: 'center', margin: '0' }}>
                    You have not yet pinned any reports. Please pin your
                    favorite reports and come back to this page.
                  </p>
                </div>
              )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Favorite;
