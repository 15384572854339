import React, { useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));




const AadiBots = () => {




  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div
              style={{
                gridGap: "10px",
                justifyContent: "flex-start",
                width: "100%",
                textAlign: "left",
                overflow: "hidden",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div>

              <iframe
      src="https://aadi-v2.streamlit.app/?embed=true"
      title="aadi"
      frameBorder="0"
    ></iframe>
        {/* <object data="https://aadi-v2.streamlit.app" type="text/html" width="1000px" height="500"></object> */}


              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default AadiBots;