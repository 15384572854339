import React, { useRef, useEffect, useState, forwardRef } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import "./QueryOptions.scss";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import UnpinPopup from "./UnpinPopup";

// const optionsItems = [
//   { id: 1, label: "Pin", icon: <PushPinIcon /> },
//   { id: 2, label: "Download Pdf", icon: <DownloadIcon /> },
// ];

const Options = ({
  setOpenOptions,
  printPDF,
  index,
  messageID,
  isPinned,
  handlePin,
  pinError,
  item,
  handleExpandClick,
  pinLoading,
}) => {
  const [open, setOpen] = useState(false);
  const optionsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    if (!open) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [open]);

  return (
    <div
      id="query-options"
      className={`${"options-container"}
      }`}
      ref={optionsRef}
    >
      <ul className="options-list">
        <li onClick={() => setOpen(true)} className="list-item">
          <div className="item-container">
            <PushPinIcon className={`${isPinned ? "active-pin" : ""}`} />
            <p className="setting-txt">Unpin</p>
          </div>
        </li>
        {item.status && (
          <li
            className="list-item"
            onClick={() => {
              handleExpandClick(item);
              setOpenOptions(false);
            }}
          >
            <div className="item-container">
              <OpenInFullIcon />
              <p className="setting-txt">Expand</p>
            </div>
          </li>
        )}
      </ul>
      {open && (
        <UnpinPopup
          open={open}
          setOpen={setOpen}
          messageID={messageID}
          pinLoading={pinLoading}
          handlePin={handlePin}
          isPinned={isPinned}
        />
      )}
    </div>
  );
};

export default Options;
