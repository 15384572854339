import React, { useState, useEffect } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import OrgTree from "react-org-tree";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const KnowledgeGraph = () => {
  const [data, setData] = useState({
    id: 0,
    label: "GSV",
    children: [
      {
        id: 2,
        label: "Time Series Analysis",
        children: [
          {
            id: 6,
            label: "YTD Variance",
          },
          {
            id: 7,
            label: "Month on Month",
          },
          {
            id: 8,
            label: "Statistical Analysis",
          },
          {
            id: 9,
            label: "Rolling Average",
          },
          {
            id: 10,
            label: "CAGR",
          },
        ],
      },
      {
        id: 3,
        label: "Decomposition Analysis",
        children: [
          {
            id: 11,
            label: "Volume- PriceMix",
          },
          {
            id: 12,
            label: "Accounting Mix (% Breakdown)",
          },
          {
            id: 13,
            label: "Product Mix (% Breakdown)",
          },
          {
            id: 14,
            label: "Segment Mix (% Breakdown)",
          },
        ],
      },
      {
        id: 1,
        label: "Benchmarking against internal factors",
      },

      {
        id: 5,
        label: "Benchmarking with macro- economic factors",
        children: [
          {
            id: 17,
            label: "Inflationary Trend",
          },
          {
            id: 18,
            label: "FX Fluctuations and Trend",
          },
          {
            id: 19,
            label: "Sector Growth",
          },
        ],
      },

      {
        id: 4,
        label: "Benchmarking against competitors",
        children: [
          {
            id: 15,
            label: "Advertising & Promotion",
          },
          {
            id: 16,
            label: "Trade expenditure",
          },
        ],
      },
    ],
  });

  const [horizontal] = useState(true);
  const [collapsable] = useState(true);
  const [expandAll] = useState(false);

  useEffect(() => {
    localStorage.setItem("activeItem", null);
  }, []);

  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div
              style={{
                gridGap: "10px",
                justifyContent: "flex-start",
                width: "100%",
                textAlign: "left",
                overflow: "hidden",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div>
                <div className="">
                  <OrgTree
                    data={data}
                    horizontal={horizontal}
                    collapsable={collapsable}
                    expandAll={expandAll}
                    renderContent={(data) => {
                      return data.label;
                    }}
                    onClick={(e, data) => {
                      // todo: handle click event
                    }}
                  ></OrgTree>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default KnowledgeGraph;
