import React from "react";
import "./DefaultLayout.scss";
import logo from "../../app/assets/img/aadi-login-logo.png";
import sideImage from '../../app/assets/img/landing-page-side-image.png'
import { useMediaQuery } from "react-responsive";


const DefaultLayout = ({ newChatStart }) => {

  let isTab = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <div className="DefaultPage">
        <img className="side-image" src={sideImage} alt="sideImage" />
        <div className="default-container">
          <div className="logo-section">
            <p>Welcome to </p>
            <img src={logo} alt="Aadi" />
          </div>

          <div className="intro-section">
            Your Decision Intelligence Co-Pilot for Finance. Navigate complex
            financial landscapes with ease and precision.
          </div>

          <div className="description-text">
            Discover the power and intelligence of Finance Co-Pilot in a highly
            distributed enterprise ecosystem. Break down information silos and
            perform root-cause analysis, flux reporting, variance/bridge
            analysis, anomaly detection and more. AaDI is powered by agentic
            workflows, knowledge graphs, Q&A and conversation capabilities,
            ensuring seamless cross-functional information exchange and enhanced
            decision-making.
          </div>

          <button
            onClick={newChatStart}
            className="ask-me-btn"
            style={{
              textTransform: "uppercase",
              borderRadius: "6px",
              position:"relative"
            }}
          >
            Ask Me
          </button>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
