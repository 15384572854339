import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const RowData = (props) => {
  const { row, index, headers } = props;
  const [open, setOpen] = useState(false);
  function formatNumber(num) {
    // If the input is not a number, return it as-is
    if (typeof num !== 'number' || isNaN(num)) {
        return num;
    }

    // Fix the number to two decimal places
    const fixedNumber = num.toFixed(2);

    // Use a regular expression to add commas for thousands
    const formattedNumber = fixedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedNumber;
}
  return (
    <>
      <TableRow
        id={`id_${index}`}
        className={index % 2 === 0 ? "tableRowEven" : "tableRowOdd"}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        {headers.slice(0, 3).map((header, colIndex) => (
          <TableCell key={colIndex} style={{ width: "10%" }} align="left">
            {formatNumber(row[header])}
          </TableCell>
        ))}

        {headers && headers.length > 3 && (
          <TableCell style={{ width: "10%" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <KeyboardArrowUpIcon
                    sx={{ fontSize: "28px", color: "rgb(187, 28, 204)" }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: "28px", color: "rgb(187, 28, 204)" }}
                  />
                )}
              </IconButton>
            </div>
          </TableCell>
        )}
      </TableRow>

      <TableRow style={{ borderBottom: "none" }}>
        <TableCell
          style={{
            paddingBottom: `${open ? "8px" : 0}`,
            paddingTop: 0,
            border: "none",
            background: "#f5f5f5",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="div"
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  paddingTop: "10px",
                  textDecoration: "underline",
                }}
                gutterBottom
                component="div"
              >
                Details Overview
              </Typography>

              <div
                style={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "100%",
                }}
              >
                {Object.entries(row).map(([key, value]) => (
                  <div style={{ display: "grid", gap: "2px" }} key={key}>
                    <span
                      style={{
                        textTransform: "uppercase",
                        color: "rgb(187, 28, 204)",
                        fontSize: "14px",
                      }}
                    >
                      <strong>{key}:</strong>
                    </span>
                    <span>{formatNumber(value) || "N/A"}</span>
                  </div>
                ))}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowData;
