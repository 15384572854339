import React, { useCallback, useEffect, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, powerbi } from "powerbi-client";
import { useHistory, useLocation } from "react-router-dom";
import HistoryChatDisplay from "../../components/history/HistoryChatDisplay";
import botImg from "../../app/assets/videos/Loading-gif.gif";
import "./HistoryPage.scss";
import { useMediaQuery } from "react-responsive";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}




const HistoryPage = () => {
  const [responceData, setresponceData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const query = useQuery();
  const id = query.get("id");
  const [isDefaultPage, setisDefaultPage] = useState(true);

  const fetchHistoryDataItem = async () => {
    setDataError(false);
    setDataLoading(true);
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/aadi/history2.0/data2.0/response/${id}`;
    try {
      const response = await axios.get(baseUrl);
      // Handle the response data as needed
      if (response.status === 200) {
        // console.log('Response Data: ',response.data);
        setresponceData(response.data);
        setDataLoading(false);
      } else {
        setDataLoading(false);
        setDataError(true);
      }
      return response.data;
    } catch (error) {
      // Handle the error as needed
      setDataLoading(false);
      setDataError(true);
      console.error("Error fetching history data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchHistoryDataItem();
    // localStorage.setItem("historyListFlag", true);
  }, []);

  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  const history = useHistory();

  const newChatStart = useCallback(() => {
    alert("hi")
    setisDefaultPage(false);
    history.push('/')

  }, []);

  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{
         display: "flex", 
        //  overflow: "hidden"
        flexDirection: isTab ? "column": "",
        overflowWrap: isTab ? "break-word" : "",
        
         
         
         }}>
        <MainLayout newChatStart={newChatStart}/>

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHistory">
            {!dataLoading && !dataError && (
              <HistoryChatDisplay responceData={responceData} newChatStart={newChatStart}/>
            )}
            {dataLoading && (
              <div className="loading-cont">
                {" "}
                <img width={120} src={botImg} alt="aadi-loading" />
                <p>Fetching History ...</p>
              </div>
            )}
            {!dataLoading && dataError && (
              <div className="loading-cont">
                <p>
                  Something Went Wrong.{" "}
                  <span
                    onClick={() => window.location.reload()}
                    className="try-again-btn"
                  >
                    Please try again.
                  </span>{" "}
                </p>
              </div>
            )}

            
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default HistoryPage;
