import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useMediaQuery } from 'react-responsive';

const SqlQueryComponent = ({ sqlQuery, index }) => {
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });
  // Function to process and format the SQL query string
  // console.log(sqlQuery)
  if (Object.keys(sqlQuery).length === 0) {
    return <div style={{ display: "none" }}>

    </div>;
  }

  const formatSqlQuery = (queryString) => {
    // Split the string by newline characters (\n) and map each line with a <div>
    return queryString.split('\n').map((line, index) => (
      <div key={index}>{line}</div>
    ));
  };

  const fontSize = isTab ? '14px' : '16px';
  return (
    <div style={{ overflow: "auto" }}>
      <div style={{ display: "grid", justifyContent: "flex-start" }}>
        <div style={{ fontSize: "18px", fontWeight: "600", paddingBottom: "1rem", paddingTop: "1rem" }}>SQL Queries {index}</div>
        <div className='moblie-layout-sql-width' style={{ background: "#1a1c24", color: "white", padding: "20px", borderRadius: "10px", width: "1000px", fontWeight: "400" }}>
          {/* {formatSqlQuery(sqlQuery)} */}
          <SyntaxHighlighter className="mobile-layout-sql" language="sql" style={okaidia} customStyle={{ fontSize }}>
            {sqlQuery}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>

  );
};

export default SqlQueryComponent;
