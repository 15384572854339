import React from "react";
import "./ScoreSection.scss";
import ScoreTable from "./ScoreTable";

const ScoreSection = ({ allScore }) => {
  function isObjectEmpty(obj) {
    // Check if the input is actually an object and not null
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      // Check if the object has any properties
      return Object.keys(obj).length === 0;
    }
    // Return false if the input is not an object or is null
    return false;
  }

  // Utility function to check if a score object is valid
  const isValidScore = (score) => {
    return (
      score && score !== "undefined" && !isObjectEmpty(score) && score !== 0
    );
  };

  const { sql_scores, Summary_scores, visualization_score } = allScore || {};

  const summaryScore = isValidScore(sql_scores)
    ? sql_scores
    : isValidScore(Summary_scores)
    ? Summary_scores
    : null;

  return (
    <div className="score-section-cont">
      {summaryScore && <ScoreTable title="Summary Score" data={summaryScore} />}
      {isValidScore(visualization_score) && (
        <ScoreTable title="Visualization Score" data={visualization_score} />
      )}
    </div>
  );
};

export default ScoreSection;
