import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import ReactMarkdown from "react-markdown";
import { useReactToPrint } from "react-to-print";
import PlotlyGrapgh from "../../components/layout/PlotlyGrapgh";
import { FaArrowRight } from "react-icons/fa";
import DataFrameComponent from "../../components/layout/DataFreamComponent";
import SqlQueryComponent from "./WorkSqlComponent";
import ScoreSection from "./ScoreSection";
import { useMediaQuery } from "react-responsive";
import Feedback from "../feedback/Feedback";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FaPlus, FaMinus } from "react-icons/fa";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WorkHeader from "./WorkHeader";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FollowUpDisplay = ({ message, messageID }) => {
  const [viewMore1, setViewMore1] = useState(false);

  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red"; // Default to red if the score is not a number
    }
    return parsedScore >= 0.75
      ? "green"
      : parsedScore >= 0.5
      ? "#FFC000"
      : "red";
  };
  const backgroundColor = getBackgroundColor(message.confidence_score);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }
  return (
    <div
      style={{
        // display: "flex",
        //  overflow: "hidden"
        flexDirection: isTab ? "column" : "",
        overflowWrap: isTab ? "break-word" : "",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {message.confidence_score !== undefined &&
          message.confidence_score !== "NA" && (
            <div
              style={{
                backgroundColor: backgroundColor,
                color:
                  parseFloat(message.confidence_score).toFixed(2) >= 0.75
                    ? "white"
                    : parseFloat(message.confidence_score).toFixed(2) >= 0.5
                    ? "black"
                    : "white",
                padding: "7px",
                borderRadius: "6px",
                fontWeight: "600",
              }}
            >
              {parseFloat(message.confidence_score).toFixed(2)}
            </div>
          )}
      </div>

      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          paddingBottom: "10px",
          paddingTop: "20px",
        }}
      >
        {message.header ? message.header : ""}
      </div>

      <span
        style={{
          fontSize: "16px",
          textAlign: "justify",
        }}
      >
        {message.text === "" && "N/A"}
        {message.text !== "" && (
          <WorkHeader
            header={
              message.summary.toLowerCase() === "na"
                ? message.message
                : message.summary
            }
          />
        )}
      </span>

      {message.figure && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
          }}
          className="plotly-cont"
        >
          <PlotlyGrapgh
            graph={message.figure}
            messageID={messageID}
            childId={message.child_question_id}
            version="1"
            tableCoumn={message.dataframe}
          />
        </div>
      )}

      <div>
        <>
          {
            <>
              <div
                className="HideMoreInPrints"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "16px",
                  width: "100%",
                  padding: "16px 0px ",
                }}
              >
                <span
                  style={{
                    // paddingRight: "1rem",
                    // color: "#fff",
                    // fontWeight: "600",
                    // cursor: "pointer",
                    // backgroundColor: "#bb1ccc",
                    // padding: "10px 6px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={``}
                  onClick={() => {
                    setViewMore1(!viewMore1);
                    // setResponseNum(1);
                  }}
                >
                  {/* {viewMore1 ? <KeyboardArrowUpIcon /> + 'Explian' :  <KeyboardArrowDownIcon />} */}
                  <p
                    style={{
                      margin: "0",
                      fontWeight: "600",
                      display: "flex",
                      fontSize: "18px",
                      color: "#bb1ccc",
                    }}
                  >
                    {viewMore1 ? (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <KeyboardArrowDownIcon />
                        <span> View Less</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <KeyboardArrowRightIcon />
                        <span>Explain</span>
                      </div>
                    )}
                  </p>
                </span>
              </div>
            </>
          }
        </>

        {viewMore1 && (
          <>
            {message.dataframe && (
              <DataFrameComponent message={message.dataframe} />
            )}

            {message.sql && (
              <>
                <div>
                  <SqlQueryComponent sqlQuery={message.sql} index="1" />
                </div>
              </>
            )}
          </>
        )}

        {viewMore1 && <ScoreSection allScore={message?.all_score} />}

        <div className="HideMoreInPrints">
          <>
            {message?.LastUpdate && (
              <div
                style={{
                  color: "#333",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginBottom: "12px",
                }}
              >
                Last Refresh: {formatDateString(message?.LastUpdate)}
              </div>
            )}
            <Feedback
              feedbackStatus={message.feedback ? message.feedback : ""}
              messageID={messageID}
              index={message.child_question_id}
              child_response_id={message.child_question_id}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default FollowUpDisplay;
