import React, { useState } from "react";
import "./WorkMessagePopup.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DisplayResponse from "./WorkDisplayResponse";
import { useMediaQuery } from 'react-responsive';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const MessagePopup = ({
  open,
  setOpen,
  message,
  index,
  printPDF,
  messageID,
  isPinned,
  handlePin,
  pinError,
  version,
  item,
  pinLoading,
  setCurrentMessage,
  currentMessage,
  setFollowUp,
  followUp
}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      id="message-popup"
    >
      {/* <DialogTitle>{"Why did you choose this rating? (optional)"}</DialogTitle> */}
      {isMobile && (
        <IconButton
          onClick={() => setOpen(false)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        {open && (
          <DisplayResponse
            message={item}
            index={item?.child_question_id ? item?.child_question : null}
            printPDF={printPDF}
            version={version}
            messageID={messageID}
            isPinned={isPinned}
            pinError={pinError}
            handlePin={handlePin}
            pinLoading={pinLoading}
            item={item}
            setCurrentMessage={setCurrentMessage}
            currentMessage={currentMessage}
            setFollowUp={setFollowUp}
            followUp={followUp}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MessagePopup;
