import React, { useRef, useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Settings.scss";
const Settings = ({ viewSettings, setViewSettings, setopenDialog }) => {
  const settingsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setViewSettings((prevState) => !prevState);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className={`${"settings-container"}
      }`}
      ref={settingsRef}
    >
      <ul className="settings-list">
        <li
          className="list-item"
          onClick={() => setopenDialog((prevState) => !prevState)}
        >
          <div className="item-container">
            <LogoutIcon className="setting-icon" />
            <p className="setting-txt">Log Out</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Settings;
