import React, { useEffect, useState } from "react";
import MessagePopup from "./MessagePopup";
import "./AnswerDisplay.scss";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactMarkdown from "react-markdown";
import PlotlyGraph from "../layout/PlotlyGrapgh";
import Feedback from "../feedback/Feedback";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QueryOptions from "./ResponseQueryOptions";
import { useMediaQuery } from "react-responsive";
import $ from 'jquery';
const AnswerDisplay = ({
  item,
  setIsLoading,
  isLoading,
  index,
  printPDF,
  responseNumber,
  responseId,
  handlePin,
  pinError,
  isPinned,
  pinLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [followUp, setFollowUp] = useState([]);

  const handleExpandClick = (item) => {
    setCurrentMessage(item);
    setOpen(true);
  };
  function trimString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str + " ...";
  }
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red";
    }
    return parsedScore > 0.75
      ? "green"
      : parsedScore > 0.5
      ? "#FFC000"
      : "red";
  };
  const backgroundColor = getBackgroundColor(item.confidence_score);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(()=>{
    $('.summary-text').each(function() {
      // Select all <p> tags inside current .summary-text element except the first one
      // $(this).find('p:not(:first-child)').remove();
      const paragraphs = $(this).find('p');
      $(this).children(':not(p, pre)').remove();
      // Check number of <p> tags
      if (paragraphs.length > 1) {
        // Remove all <p> tags except the first one
        paragraphs.slice(1).remove();
      }
    });
  },[])


  return (
    <div className="response-card">
      <div className="first-info-cont mobile-header-layout">

        <div style={{ display: "flex", justifyContent: item.header && isTab ? "space-between" : "space-between", width: "100%", alignContent: "center", alignItems: "center", paddingBottom: "10px" }}>
          <div className="mobileLayout-width"  style={{ width: isTab ? "60%" : "80%" }}>
            <span className="response-header">{item.header ? item.header : ""}</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              justifyItems: "center",
            }}
            className="mobile-score-layout"

          >
            {item.confidence_score !== undefined &&
              item.confidence_score !== "NA" && (
                <div
                  style={{
                    backgroundColor: backgroundColor,
                    color:
                      parseFloat(item.confidence_score).toFixed(2) > 0.75
                        ? "white"
                        : parseFloat(item.confidence_score).toFixed(2) > 0.5
                        ? "black"
                        : "white",
                    padding: "7px",
                    borderRadius: "6px",
                    // position: "absolute",
                    // top: "10px",
                    // right: "45px",
                    fontWeight: "600",
                  }}
                >
                  {parseFloat(item.confidence_score).toFixed(2)}
                </div>
              )}
            <div
              className={`three-dots-icon ${
                openOptions ? "option-active" : ""
              }`}
              onClick={() => {
                setOpenOptions((prevState) => true);
              }}
            >
              <MoreVertIcon />
            </div>
          </div>
        </div>
        {/* {item.status && (
        <div
          className="expand-icon"
          onClick={() => handleExpandClick(item)} // Pass the clicked item's text to the handler
        >
          <OpenInFullIcon />
        </div>
      )} */}
      </div>
      <ReactMarkdown className="summary-text">
        {item.summary.toLowerCase() === "na"
          ? item.message
          : responseNumber > 1
          ? trimString(item.summary, 600)
          : item.summary}
      </ReactMarkdown>
      {item.summary && item.summary.toLowerCase() !== "na" && (
        <p className="read-more-btn" onClick={() => handleExpandClick(item)}>
          Read More
        </p>
      )}
      {item.figure && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            marginTop: "16px",
          }}
          className="plotly-cont"
        >
          <PlotlyGraph
            graph={item.figure}
            childId={item.child_question_id}
            version={item.version ? item.version : 1}
            AnswerDisplay="AnswerDisplay"
            messageID={responseId}
            tableCoumn={item.dataframe}
          />
        </div>
      )}
      <div className="HideMoreInPrints">
        <>
          <Feedback
            feedbackStatus={item.feedback ? item.feedback : ""}
            messageID={responseId}
            index={index}
            child_response_id={index}
          />
        </>
      </div>
      <MessagePopup
        open={open}
        setOpen={setOpen}
        item={currentMessage}
        index={index}
        printPDF={printPDF}
        messageID={responseId}
        isPinned={isPinned}
        pinError={pinError}
        handlePin={handlePin}
        version={item.version ? item.version : 1}
        pinLoading={pinLoading}
        setCurrentMessage={setCurrentMessage}
        currentMessage={currentMessage}
        followUp={followUp}
        setFollowUp={setFollowUp}
      />{" "}
      {/* Pass the current message to the popup */}
      {openOptions && (
        <QueryOptions
          handleExpandClick={handleExpandClick}
          setOpenOptions={setOpenOptions}
          isPinned={isPinned}
          pinError={pinError}
          handlePin={handlePin}
          item={item}
          messageID={responseId}
          pinLoading={pinLoading}
        />
      )}
    </div>
  );
};

export default AnswerDisplay;
