import React, { useState, useEffect } from "react";
import ReportGenerate from "../Reusable/ReportGenerate";
import ReactPaginate from "react-paginate";
import RowData from "./RowData";

const DataFrameComponent = ({ message }) => {
  const [currentPagelimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const excelData = transformNestedObject(message);
    const numOfRecords = excelData.length;
    setPageCount(Math.ceil(numOfRecords / currentPagelimit));

    // Update the current data to display based on the current page and limit
    const dataToDisplay = excelData.slice(
      (currentPage - 1) * currentPagelimit,
      currentPage * currentPagelimit
    );
    setCurrentData(dataToDisplay);
  }, [message, currentPage, currentPagelimit]);
  // Function to handle page click
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const generateTable = (data) => {
    if (!data || Object.keys(data).length === 0) {
      return <div>No data available</div>;
    }

    const headers = Object.keys(data);
    const numRows = Object.keys(data[headers[0]]).length; // Assuming all headers have the same length
    const allRows = Array(numRows).fill({});

    // Construct rows
    Object.keys(data[headers[0]]).forEach((rowIndex) => {
      headers.forEach((header) => {
        allRows[rowIndex] = {
          ...allRows[rowIndex],
          [header]: data[header][rowIndex] ?? "N/A",
        };
      });
    });

    const excelData = transformNestedObject(message);

    return (
      <div style={{overflow:"auto"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="table-info-mobile-main"

        >
          <div
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginTop: "10px",
              paddingBottom: "20px",
            }}
            className="table-info-mobile"
          >
            Table Information
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div>
              {pageCount > 1 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={currentPage - 1}
                />
              )}
            </div>
            <ReportGenerate
              excelData={excelData}
              fileName={`table_data_${new Date().getTime()}`}
              sheetName="Table Data"
            />
          </div>
        </div>
        <table style={{ width: "100%" }} id="chatTable">
          <thead>
            <tr>
              {headers.slice(0, 3).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              {headers && headers.length > 3 && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, rowIndex) => (
              <RowData
                allRows={allRows}
                key={rowIndex}
                index={rowIndex }
                row={row}
                headers={headers}
                currentPage={currentPage}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Function to transform nested object into an array of objects
  const transformNestedObject = (data) => {
    const result = [];
    const length = Object.values(data)[0]
      ? Object.keys(Object.values(data)[0]).length
      : 0;
    for (let i = 0; i < length; i++) {
      const item = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          item[key] = data[key][i];
        }
      }
      result.push(item);
    }
    return result;
  };

  return generateTable(message);
};

export default DataFrameComponent;

{
  /* <tr key={rowIndex}>
{headers.map((header, colIndex) => (
  <td key={colIndex}>{allRows[rowIndex][header]}</td>
))}
</tr> */
}
