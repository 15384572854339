import React, { useState } from "react";
import Plot from "react-plotly.js";
import DisplayPlotlyGraph from "../replot/DisplayPlotlyGrapgh";
import { useMediaQuery } from "react-responsive";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PlotlyGraph = ({
  graph,
  messageID,
  childId,
  version,
  AnswerDisplay,
  tableCoumn
}) => {
  const [GraphData, setGraphData] = useState(graph);
  const [openPlotly, setOpenPlotly] = useState(false);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  // Check if graph is an empty object
  if (Object.keys(GraphData).length === 0) {
    return <div style={{ display: "none" }}></div>;
  }

  const graphData = JSON.parse(GraphData);
  const headers = tableCoumn && Object.keys(tableCoumn);

  // Hook call at the top level

  let layoutData = {};

  if (isTab) {
    layoutData = {
      ...graphData.layout,
      legend: {
        orientation: "h",
        y: -0.7, // Adjusted to position below the plot
        x: 0.5, // Center horizontally
        xanchor: "center", // Center anchor
      },
    };
  } else {
    layoutData = {
      ...graphData.layout,
      legend: {
        x: 1.2,
      },
    };
  }

  return (
    <div>
      <div className="plotly-item-container" onClick={() => setOpenPlotly(true)}>
        <OpenInFullIcon />
      </div>
      <Plot
        className="mobile-layout-graph"
        data={graphData.data}
        layout={layoutData}
        config={{
          displaylogo: false, // Removes the "Produced with Plotly.js" watermark
        }}
      />
      {!AnswerDisplay && (
        <DisplayPlotlyGraph
          setGraphData={setGraphData}
          messageID={messageID}
          childId={childId}
          version={version}
          AnswerDisplay={AnswerDisplay}
          headers={headers}
        />
      )}
      {openPlotly && (
        <Dialog
          open={openPlotly}
          keepMounted
          onClose={() => setOpenPlotly(false)}
          aria-describedby="alert-dialog-slide-description"
          id="plotly-popup"
        >
          {isTab && (
            <IconButton
              onClick={() => setOpenPlotly(false)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <DialogContent>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
              className="plotly-cont"
            >
              <Plot
                className="mobile-layout-graph"
                data={graphData.data}
                layout={layoutData}
                config={{
                  displaylogo: false, // Removes the "Produced with Plotly.js" watermark
                }}

                
              />

{!AnswerDisplay && (
        <DisplayPlotlyGraph
          setGraphData={setGraphData}
          messageID={messageID}
          childId={childId}
          version={version}
          AnswerDisplay={AnswerDisplay}
          headers={headers}
ß        />
      )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default PlotlyGraph;
