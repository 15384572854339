import React, { useEffect, useState } from "react";
import FavMessagePopup from "./FavMessagePopup";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ReactMarkdown from "react-markdown";
import PlotlyGraph from "../../components/layout/PlotlyGrapgh";
import Feedback from "../../components/feedback/Feedback";
import PushPinIcon from "@mui/icons-material/PushPin";
import Options from "./QueryOptions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMediaQuery } from "react-responsive";
import $ from 'jquery';
const AnswerDisplay = ({
  item,
  index,
  printPDF,
  responseNumber,
  responseId,
  handlePin,
  pinError,
  isPinned,
  message,
  pinLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [openOptions, setOpenOptions] = useState(false);

  const handleExpandClick = (item) => {
    setCurrentMessage(item);
    setOpen(true);
  };

  function trimString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str + " ...";
  }
  const getBackgroundColor = (score) => {
    const parsedScore = parseFloat(score);
    if (isNaN(parsedScore)) {
      return "red";
    }
    return parsedScore > 0.75
      ? "green"
      : parsedScore > 0.5
        ? "#FFC000"
        : "red";
  };
  const backgroundColor = getBackgroundColor(item.confidence_score);
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  useEffect(()=>{
    $('.summary-text').each(function() {
      // Select all <p> tags inside current .summary-text element except the first one
      // $(this).find('p:not(:first-child)').remove();
      const paragraphs = $(this).find('p');
      $(this).children(':not(p, pre)').remove();

      // Check number of <p> tags
      if (paragraphs.length > 1) {
        // Remove all <p> tags except the first one
        paragraphs.slice(1).remove();
      }
    });
  },[])

  return (
    <div
      className="response-card"
      style={{
        // overflowX: isTab ? "auto" : "visible",
        // whiteSpace: isTab ? "nowrap" : "normal",
      }}
    >

      <div className="mobileLayout-width"   style={{  display:isTab ? "flex": "flex", width: "100%", justifyContent: item.header && isTab ?"space-between": "space-between", alignContent: "center", alignItems: "center" }}>
        <p className="response-header">{item.header ? item.header : ""}</p>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              justifyItems: "center",
            }}
            className="mobile-score-layout"

          >
            {item.confidence_score !== undefined &&
              item.confidence_score !== "NA" && (
              <div
                style={{
                  backgroundColor: backgroundColor,
                  color:
                    parseFloat(item.confidence_score).toFixed(2) > 0.75
                      ? "white"
                      : parseFloat(item.confidence_score).toFixed(2) > 0.5
                        ? "black"
                        : "white",
                  padding: "7px",
                  borderRadius: "6px",
                  fontWeight: "600",
                }}
              >
                {parseFloat(item.confidence_score).toFixed(2)}
              </div>
            )}
            {/* <div className="avatar-container">
            <div className="pin-cont">
              <PushPinIcon
                className={`pin-icon ${isPinned ? "pinned" : ""}`}
                onClick={() => handlePin(message?.message_id)}
              />
            </div>
          </div>
          {item.status && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleExpandClick(item)} // Pass the clicked item's text to the handler
            >
              <OpenInFullIcon />
            </div>
          )} */}
            <div
              className={`three-dots-icon ${openOptions ? "option-active" : ""}`} 
              onClick={() => {
                setOpenOptions((prevState) => true);
              }}
            >
              <MoreVertIcon />
            </div>
          </div>
        </div>
      </div>

      {item.figure && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            marginTop: "16px",
          }}
          className="plotly-cont"
        >
          <PlotlyGraph
            graph={item.figure}
            childId={item.child_question_id}
            version={item.version ? item.version : 1}
            AnswerDisplay="AnswerDisplay"
            messageID={responseId}
          />
        </div>
      )}
      <ReactMarkdown className="summary-text">
        {item.summary.toLowerCase() === "na"
          ? item.message
          : responseNumber > 1
            ? trimString(item.summary, 600)
            : item.summary}
      </ReactMarkdown>
      {item.summary && item.summary.toLowerCase() !== "na" && (
        <p className="read-more-btn" onClick={() => handleExpandClick(item)}>
          Read More
        </p>
      )}
      {item?.LastUpdate && (
        <div
          style={{
            color: "#333",
            fontWeight: "600",
            fontSize: "12px",
            marginTop: "24px",
            textAlign: "left",
          }}
        >
          Last Refresh: {formatDateString(item?.LastUpdate)}
        </div>
      )}

      <div class="HideMoreInPrints">
        <>
          <Feedback
            feedbackStatus={item.feedback ? item.feedback : ""}
            messageID={responseId}
            index={index}
            child_response_id={index}
          />
        </>
      </div>
      <FavMessagePopup
        open={open}
        setOpen={setOpen}
        message={currentMessage}
        index={index}
        printPDF={printPDF}
        messageID={responseId}
        isPinned={isPinned}
        pinError={pinError}
        handlePin={handlePin}
        version={item.version ? item.version : 1}
        pinLoading={pinLoading}
        item={item}
      />{" "}
      {/* Pass the current message to the popup */}
      {openOptions && (
        <Options
          handleExpandClick={handleExpandClick}
          setOpenOptions={setOpenOptions}
          isPinned={isPinned}
          pinError={pinError}
          handlePin={handlePin}
          item={item}
          messageID={responseId}
          pinLoading={pinLoading}
        />
      )}
    </div>
  );
};

export default AnswerDisplay;
