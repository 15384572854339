import { Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const Faqs = ({
  ChangeTab,
  tabValue,
  handleExampleClick,
  nodataImage,
  removeFromSessionHistory,
  fetchHistoryDataItem,
  setUserInput,
  handleExternalTextClick,
}) => {
  const [faq, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const faqData = async () => {
    try {
      setLoading(true);
      const clientId = `?Client_Id=${process.env.REACT_APP_TOOL_CLIENT}`;
      const baseurl = `${process.env.REACT_APP_BASE_URL}/aadi/faqs` + clientId;

      // Making the GET request
      const response = await axios.get(baseurl);

      // Handling the response
      if (response.status === 200) {
        setFaqs(response.data);
        setLoading(false);

        return response.data;
      } else {
        console.error(
          "Error fetching FAQ data:",
          response.status,
          response.statusText
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    faqData();
  }, []);

  return (
    <div className="">
      <div className="">
        <div className="card text-center">
          <div
            style={{
              textAlign: "left",
              paddingBottom: "1rem",
              fontSize: "18px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Related frequently asked questions
          </div>
          <div className="card-body">
            {loading && (
              <div
                style={{
                  padding: "0.5rem",
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    style={{ width: "300px" }}
                    key={index}
                    height={60}
                  />
                ))}
              </div>
            )}

            {!loading && (
              <>
                {faq.length !== 0 && (
                  <div
                    style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                  >
                    {faq &&
                      faq.slice(0, 4).map((item, index) => (
                        <div
                          key={index}
                          className="mt-3 justify-content-center"
                        >
                          <div
                            id={`faqsId_${item.Id}`}
                            className="bg-dark-card"
                            style={{border: '1px solid #bb1ccc'}}
                          >
                            <div className="text-start faqtext">
                              <span
                                onClick={() =>
                                  // setUserInput(item.Question)
                                  handleExternalTextClick(item.Question)
                                }
                                style={{ fontSize: "14px",color:"black" }}
                              >
                                {item.Question}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                {faq.length === 0 && (
                  <>
                    <div
                      style={{
                        height: "60vh",
                        display: "grid",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        top: 30,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <div>
                        <img src={nodataImage} width={200} alt="nodata" />
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridGap: "6px",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          No Frequently Asked Questions
                        </span>
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            margin: "auto",
                            color: "gray",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Despite the absence of an FAQ section, we are here to
                          assist you. Feel free to reach out to our support team
                          with any questions or concerns you may have.{" "}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div style={{ display: "grid", gap: "10px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
