import React from "react";
import botImg from "../../app/assets/videos/Loading-gif.gif";

const HistoryLoadingPage = ({ question }) => {
  return (
    <>
      <div>
        <div className="fullHeight">
          <div
            style={{
              width: "100%",
              color: "white",
              display: "grid",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              gap: "20px",
            }}
          >
            <span>
              <img width={150} src={botImg} alt="bot" />
            </span>
            <span style={{ fontSize: "20px", color: "black" }}>
              {" "}
              Fetching History{" "}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryLoadingPage;
