import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useMediaQuery } from "react-responsive";
import EditIcon from "@mui/icons-material/Edit";
import Alert from '@mui/material/Alert';
import "./WorkSqlComponent.scss";

const SqlQueryComponent = ({ sqlQuery, index }) => {
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [editableQuery, setEditableQuery] = useState(sqlQuery); // State to hold the editable query
  const [currentQuery, setCurrentQuery] = useState(sqlQuery);
  const [sqlLoading, setSqlLoading] = useState(false);
  const [sqlSuccess, setSqlSuccess] = useState(false)
  const isTab = useMediaQuery({ query: "(max-width: 768px)" });

  // Function to handle the click on the edit icon
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Function to handle the change in textarea
  const handleTextareaChange = (event) => {
    setEditableQuery(event.target.value);
  };

  // Function to handle the click on the save button
  const handleSaveClick = () => {
    setSqlLoading(true);
    setTimeout(() => {
      setSqlLoading(false);
      setCurrentQuery(editableQuery);
      setIsEditing(false);
      setSqlSuccess(true)
    }, 2000);

    setTimeout(() => {
      setSqlSuccess(false)
    }, 6000);
  };

  // Function to handle the click on the cancel button
  const handleCancelClick = () => {
    setEditableQuery(currentQuery); // Revert changes
    setIsEditing(false);
  };

  const fontSize = isTab ? "14px" : "16px";
  if (Object.keys(sqlQuery).length === 0) {
    return <div style={{ display: "none" }}></div>;
  }

  return (
    <div style={{ overflow: "auto" }}>
      <div style={{ display: "grid", justifyContent: "flex-start" }}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingBottom: "1rem",
            paddingTop: "1rem",
          }}
        >
          SQL Queries {index}
        </div>
        <div
          style={{
            background: `${isEditing ? "#fff" : "#1a1c24"}`,
            color: "white",
            padding: "20px",
            borderRadius: "10px",
            width: "1000px",
            fontWeight: "400",
            position: "relative",
          }}
        >
          {isEditing ? (
            <div>
              <textarea
                value={editableQuery}
                onChange={handleTextareaChange}
                style={{
                  width: "100%",
                  height: "350px",
                  fontWeight: "600",
                  padding: "16px",
                }}
              />
              <div style={{ marginTop: "10px" }}>
                <button
                  onClick={handleSaveClick}
                  className="submit_btn work-bench-save"
                  style={{ marginRight: "10px" }}
                >
                  {sqlLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span style={{ paddingLeft: "10px" }}>Saving ...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  onClick={handleCancelClick}
                  className="cancel-btn work-bench-cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div>
              <SyntaxHighlighter
                language="sql"
                style={okaidia}
                customStyle={{ fontSize }}
              >
                {currentQuery}
              </SyntaxHighlighter>
              <EditIcon
                className="workbench-sql-edit-icon"
                onClick={handleEditClick}
              />
            </div>
          )}
          { sqlSuccess && <Alert style={{position:'absolute', top:'2px', left:"40%", width:'25%'}} severity="success">SQL updated successfully.</Alert>}
        </div>
      </div>
    </div>
  );
};

export default SqlQueryComponent;
